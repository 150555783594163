export let button = {
  MuiButton: {
    styleOverrides: {
      textTransform: "lowercase",

      root: {
        textTransform: "lowercase",
        color: "#000000 !important",

        color: "#000000",

        fontWeight: "bold",
        borderRadius: 0,
        // ...other common styles
      },
      containedPrimary: { color: "red !important", fontSize: "80px" },
      header: {
        p: "0",
        height: "fit-content",
        fontWeight: "normal",
        textTransform: "initial",
      },
      loginBtn: {
        background: "#000000",
        color: "#FFFFFF",
        fontSize: "18px",
        textTransform: "uppercase",
        height: "59px",

        "&:hover": {
          // Add your custom hover styles here
          background: "#191919 !important",
          // Add any other styles you want to apply on hover
        },
      },
      createAccount: {
        textDecoration: "underline",
        fontSize: "18px",
        color: "#FFFFFF",
        textTransform: "capitalize",
      },
      downloadNow: {
        background: "#E8112A",
        borderRadius: "25px",
        width: "236px",
        color: "#FFFFFF",
        fontSize: "13px",

        height: "40px",
        textTransform: "capitalize",
        "&:hover": {
          // Add your custom hover styles here
          background: "#E8112A !important",
          // Add any other styles you want to apply on hover
        },
      },
      shareMail: {
        background: " #000000",
        textTransform: "capitalize",
        borderRadius: "25px",
        width: "236px",
        color: "#FFFFFF",
        fontSize: "13px",

        height: "40px",
      },
      saveChanges: {
        borderRadius: "25px",
        width: "185px",
        height: "40px",
        background: "#000000",
        color: "#FFFFFF",
        fontSize: "13px",
        textTransform: "capitalize",
      },
      cancel: {
        borderRadius: "25px",
        width: "125px",
        height: "40px",
        background: "#FFFFFF",
        color: "#000000",
        fontSize: "13px",
        border: "1px solid #000000",
        textTransform: "capitalize",
      },
      alertBtn: { textTransform: "capitalize", color: "red", fontSize: "18px" },
      // Button variants Here

      // ...other custom variants
    },
  },
};
