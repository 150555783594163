export const downloadPdf = (pdfFileUrl) => {
  const pdfUrl = pdfFileUrl;

  // Create an anchor element
  const downloadLink = document.createElement("a");

  // Set the href attribute to the PDF file URL
  downloadLink.href = pdfUrl;

  // Set the download attribute to the desired file name
  downloadLink.download = "example.pdf";

  downloadLink.target = "_blank";
  // Change "example.pdf" to the desired file name

  // Append the anchor element to the body
  document.body.appendChild(downloadLink);

  // Programmatically trigger a click event on the anchor element
  downloadLink.click();

  // Remove the anchor element from the body
  document.body.removeChild(downloadLink);
};
