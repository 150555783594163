import React, { useState } from "react";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";
import { homeHeroContent } from "../../mock_data";
import { homePageSecondaryTextStyles } from "../../jsxStyles";
import Box from "@mui/material/Box";
import LearnMoreDialog from "../Global/LearnMoreDialog";

const Hero = () => {
  const [open, setOpen] = useState("");

  return (
    <Box>
      <LearnMoreDialog open={open} setOpen={setOpen} />
      <Typography
        component="h1"
        variant="h6"
        sx={{
          textAlign: "center",
          fontSize: "1.8rem",
          fontWeight: 700,
        }}
      >
        {homeHeroContent.title}
      </Typography>
      <Typography
        component="p"
        variant="body1"
        sx={{
          ...homePageSecondaryTextStyles,
          fontSize: "1.2rem",
          lineHeight: "1.5",
        }}
      >
        {homeHeroContent.content}
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          mt: "17.31px",
        }}
      >
        <Link
          style={{
            color: "#E8112A",
            letterSpacing: "0.07px",
            fontSize: "1.0rem",
          }}
          onClick={() => setOpen(true)}
        >
          Conocer más
        </Link>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Box
          component="img"
          src="/illustration.svg"
          sx={{
            maxWidth: {
              sm: "35%",
              md: "50%",
              lg: "60%",
              xl: "75%",
            },
            mt: "51px",
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
