import React, { useState } from "react";

const MethodologyGuide = () => {
  const [selectedButton, setSelectedButton] = useState("Button 1");

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  // Define UI components directly inside MethodologyGuide component
  const UIComponent1 = () => (
    <div className="description-font" style={{ padding: "30px" }}>
      Para llevar a cabo el diligenciamiento de la Herramienta de la Huella de
      Publicidad Responsable se recomienda al anunciante que involucre y/
      consulte a las áreas encargadas de planear y diseñar la publicidad,
      generar los soportes técnicos y/o científicos y aprobar la publicidad. A
      su vez, resultaría relevante que quienes estén involucrados hagan parte de
      la alta dirección, las áreas técnicas, legales y de sostenibilidad.
      <div style={{ display: "flex", marginTop: "30px" }}>
        <img
          style={{
            flex: "0 0 3%",
            width: "20px",
            height: "34px",
            marginRight: "15px",
          }}
          src="/apple.png"
          alt="apple"
        />
        <div style={{ flex: "0 0 97%" }}>
          <p style={{ fontWeight: "bolder" }}>
            Entendiendo el ejercicio e identificando responsables:
          </p>
          Se recomienda que el equipo de trabajo asignado para el desarrollo de
          este ejercicio, antes de iniciar la evaluacion, conozca el presente
          instructivo y la herramienta, para que posteriormente pueda
          identificar qué areas podrian participar y establecer quiénes son los
          principales responsables de la informacién que se diligenciara.
        </div>
      </div>
      <br></br>
      <div style={{ display: "flex" }}>
        <img
          style={{
            flex: "0 0 3%",
            width: "20px",
            height: "35px",
            marginRight: "15px",
          }}
          src="/scope.png"
          alt="scope"
        />
        <div style={{ flex: "0 0 97%" }}>
          <p style={{ fontWeight: "bolder" }}>
            Socializando los objetivos del ejercicio:
          </p>
          Una vez identificados los responsables, se recomienda al equipo lider
          socializar con las areas invitadas, los objetivos y propdésitos de la
          organizacion en relacion con el ejercicio y el propdsito de la ANDA al
          crear esta iniciativa. Asi mismo, se recomienda al equipo de trabajo
          leer y analizar todas las preguntas y asi obtener una primera
          percepcion del estado actual de la organizacion frente a los criterios
          indagados. De esta actividad se espera que el equipo lider divida las
          preguntas segtn el area que corresponda y los responsables se
          comprometan en la recoleccion de la informacion que les permitira dar
          respuesta a las preguntas de la herramienta y brindar soporte, en caso
          de ser necesario."
        </div>
      </div>
      <br></br>
      <div style={{ display: "flex" }}>
        <img
          style={{
            flex: "0 0 3%",
            width: "20px",
            height: "40px",
            marginRight: "15px",
          }}
          src="/checksheet.png"
          alt="checksheet"
        />
        <div style={{ flex: "0 0 97%" }}>
          <p style={{ fontWeight: "bolder" }}>Evaluación:</p>
          Una vez se socialice la actividad, se recomienda dividir las preguntas
          por responsables y definir fechas internas de entrega de la
          información. Luego el equipo líder deberá consolidar la información y
          una vez revisada y validada internamente, la herramienta diligenciada
          deberá ser subida a la plataforma BIA ANDA en las fechas establecidas.
        </div>
      </div>
      <br></br>
      <div style={{ display: "flex" }}>
        <img
          style={{
            flex: "0 0 3%",
            width: "20px",
            height: "40px",
            marginRight: "13px",
          }}
          src="/document.png"
          alt="document"
        />
        <div style={{ flex: "0 0 97%" }}>
          <p style={{ fontWeight: "bolder" }}>
            Análisis de los resultados obtenidos:
          </p>
          Una vez recibidos los cuestionarios, la ANDA realizará el respectivo
          análisis, revisión de resultados y reporte final por empresa y a nivel
          gremio. La información y resultados por empresa son totalmente
          confidenciales.
        </div>
      </div>
      <br></br>
    </div>
  );
  const UIComponent2 = () => (
    <div className="description-font" style={{ padding: "30px" }}>
      La herramienta de Huella de Publicidad Responsable está automatizada y
      solo requiere que el equipo responsable registre o seleccione, de una
      lista desplegable, la respuesta otorgada a cada criterio e incluya
      información cualitativa cuando corresponda.<br></br>
      <br></br>Las opciones de respuesta son:<br></br>
      (i) Si/No/No aplica <br></br>(ii) Opción múltiple (se incluyen varias
      opciones predeterminadas y la empresa deberá seleccionar las que le
      aplican a su empresa; si las opciones predeterminadas no coinciden con lo
      que aplica a su empresa o implementa otras acciones, puede diligenciar
      información adicional con la opción ""otras"")<br></br> (iii) Información
      adicional cualitativa (los participantes deberán incluir información
      adicional que pueda nutrir la respuesta y el análisis respecto a las
      mejores prácticas de la empresa). <br></br>
      <br></br>La herramienta automáticamente suma los resultados, establece un
      promedio por principio evaluado y calcula un porcentaje de compromiso de
      la organización por cada área y principio respecto a las buenas prácticas
      en materia de publicidad responsable. Dichos resultados son visibles y
      revisados únicamente por el equipo de la ANDA, quienes realizan un
      análisis para emitir un informe de la gestión y el desempeño general de la
      empresa frente a las buenas prácticas y conceptos evaluados de publicidad
      responsable."
    </div>
  );
  const UIComponent3 = () => (
    <div style={{ padding: "20px 5px" }}>
      <img src="/principles.png" alt="principles" />
    </div>
  );
  const UIComponent4 = () => (
    <div className="description-font" style={{ padding: "30px" }}>
      1. Para cada una de las preguntas encontrará en las ventanas emergentes de
      comentarios, el ""Razonamiento y Propósito de la pregunta"", ya que
      entendemos que en el desarrollo del diligenciamiento se pueden presentar
      diferencias de interpretación entre los diferentes miembros del equipo,
      por eso y con el fin de guiar el debate en torno a los criterios
      evaluados, en dichas ventanas emergentes se podrán encontrar algunas
      definiciones importantes, el contexto o explicación general para cada
      pregunta y el propósito general de por qué la ANDA está indagando sobre
      dicha gestión. Con esto se pretende aclarar el alcance de cada una de las
      preguntas y mitigar riesgos de respuestas erróneas. <br></br>
      <br></br>2. Así mismo, en la pestaña ""Glosario"" podrá encontrar algunas
      definiciones clave que lo ayudarán a dar respuesta a las preguntas en caso
      lo necesite. <br></br>
      <br></br>
      3. El documento está protegido para que no se generen cambios en las
      preguntas y estructura del archivo Excel, es decir que el
      participante/anunciante solo podrá incluir información en los campos
      habilitados. <br></br>
      <br></br>4. Es importante que l Anunciante responda a todas las preguntas
      y verifique la adecuada contestación de las mismas. Con la finalidad de
      falicitar dicho proceso de verificación, al final de cada principio el
      anunciante encontrará un cuadro de control con el status de las respuestas
      a cada pregunta del principio. El status, indica si la pregunta en su
      contestación a nivel cuantitativo (por ejemplo, respuestas de tipo
      Sí/No/No aplica) y cualitativo (casilla de comentarios) procede acorde a
      las indicaciones."{" "}
    </div>
  );

  return (
    <div className="main-container">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="button-container"
        >
          <button
            className={
              selectedButton === "Button 1"
                ? "selected-button header-font "
                : "unselected-button header-font "
            }
            onClick={() => handleButtonClick("Button 1")}
          >
            Instructivo
          </button>
          <button
            className={
              selectedButton === "Button 2"
                ? "selected-button header-font"
                : "unselected-button header-font"
            }
            onClick={() => handleButtonClick("Button 2")}
          >
            Preguntas
          </button>
          <button
            className={
              selectedButton === "Button 3"
                ? "selected-button header-font"
                : "unselected-button header-font"
            }
            onClick={() => handleButtonClick("Button 3")}
          >
            Ruta general
          </button>
          <button
            className={
              selectedButton === "Button 4"
                ? "selected-button header-font"
                : "unselected-button header-font"
            }
            onClick={() => handleButtonClick("Button 4")}
          >
            Consideraciones finales
          </button>
        </div>
        <div className="content-container">
          {selectedButton === "Button 1" && <UIComponent1 />}
          {selectedButton === "Button 2" && <UIComponent2 />}
          {selectedButton === "Button 3" && <UIComponent3 />}
          {selectedButton === "Button 4" && <UIComponent4 />}
        </div>
      </div>
    </div>
  );
};

export default MethodologyGuide;
