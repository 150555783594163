export let footerItems = [
  { name: "ANDA © 2023" },
  {
    name: "Conócenos",
    link: "https://www.andacol.com/index.php/anda/nosotros",
  },
  {
    name: "Nuestros afiliados",
    link: "https://www.andacol.com/index.php/afiliados",
  },
  {
    name: "Eventos",
    link: "https://www.andacol.com/index.php/anda/eventos-anda",
  },

  {
    name: "Noticias",
    link: "https://www.andacol.com/index.php/noticias-anda-col/comunicados#",
  },
  {
    name: "Terminos y condiciones",
    link: "https://www.andacol.com/index.php/privacy-policy",
  },
];

export let footerSocialMediaItems = [
  { link: "", src: <i className="fa fa-twitter" aria-hidden="true"></i> },
  { link: "", src: <i className="fa fa-twitter" aria-hidden="true"></i> },
  { link: "", src: <i className="fa fa-twitter" aria-hidden="true"></i> },
];

export let homeHeroContent = {
  title: "Bienvenidos a nuestra plataforma tecnológica para afiliados",
  content: `Aquí podrás acceder de manera sencilla a todos los documentos, presentaciones y temas relevantes del mercadeo. Explora, aprende y mantente actualizado. Juntos, seguiremos construyendo el futuro de la ANDA. ¡Disfruta de esta experiencia!`,
};

export let LearnMoreContent = {
  content: (
    <>
      <div style={{ fontSize: "24px" }}>Estimados Afiliados de la ANDA,</div>
      <br /> <br />
      Les damos una especial bienvenida a nuestra plataforma digital <b>BIA</b>(
      <b>B</b>úsqueda <b>I</b>nteligente para <b>A</b>filiados){" "}
      <b>ANDA COLOMBIA</b>, un espacio diseñado especialmente para ustedes,
      donde podrán acceder a recursos y contenidos de gran relevancia, de y para
      nuestra industria.
      <br /> <br />
      Tendrán acceso directo a temas de gran importancia como: documentos
      legales para consulta, presentaciones de los diferentes comités, así como
      publicaciones y noticias relevantes de la WFA (World Federation of
      Advertisers) que nos mantendrán al tanto de las últimas tendencias y
      novedades a nivel mundial.
      <br /> <br />
      Los invitamos a explorar este recurso, familiarizarse con sus distintas
      secciones y aprovechar al máximo todas las oportunidades que ofrece.
      <br /> <br />
      Cuando estamos en línea con la transformación digital,
      <b>¡Colombia ANDA!</b>
      <br />
      <br />
      ¡Bienvenidos a nuestra nueva plataforma!
    </>
  ),
};

export let dashboardSideNavItems = [
  "Asociación Nacional de Anunciantes de Colombia",
  "Gobierno y estructura",

  "Formación",
  "Sostenibilidad",
  "Regulación y auto regulación",
  "Comunicaciones",
  "Federación Nacional de Anunciantes",
  "Entidades de gobierno",
];
export let dashboardIntroContent = `Nos enorgullece utilizar la última tecnología en Inteligencia Artificial para
agilizar y simplificar el acceso a archivos históricos, actas, presentaciones,
manuales, infografías, leyes, proyectos de ley, guías, notas de prensa,
publicaciones, entre otras y toda la información relevante relacionada con la
Asociación Nacional de Anunciantes de Colombia.`;

export let dashboardIntroLowerContent = `Esperamos sea de gran utilidad para ustedes, nuestros afiliados.`;

export let dashboardIntroMiddleContent = `Con nuestra plataforma, puedes estar seguro que te proporcionaremos las
herramientas necesarias para que obtengas la documentación que necesitas, de
manera eficiente, actualizada y oportuna`;

export let noCategoryAndSubcategoryDialogueContent = `Para lograr resultados más rápidos y precisos, recomendamos seleccionar una
categoría y subcategoría. Si no está seguro, simplemente haga clic en el botón
"Continuar buscando". Tenga en cuenta que no elegir una
<b style='color:black'> categoría/subcategoría</b> conducirá a una búsqueda en la cual el tiempo de
respuesta será más larga ya que tiene que buscar en todas las
categorías/subcategorías antes de obtener resultados.`;
