import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Introduction from "./Global/Introduction";
import Objectives from "./Global/Objectives";
import DataHistory from "./Global/DataHistory";
import Principles from "./Global/Principles";
import MethodologyGuide from "./Global/MethodologyGuide";
import Glossary from "./Global/Glossary";
import TermsOfUse from "./Global/TermsOfUse";
import CompanyInfo from "./Global/CompanyInfo";
import { useLocation } from "react-router";
import { CloseFullscreen } from "@mui/icons-material";
import {
  useQueryGetListOfPrinciple,
  useQueryGetQuestionsOfPrinciples,
  useQueryGetUserDocumentStatus,
} from "../hooks/react-query/useData";
import SinglePrinciple from "./Global/SinglePrinciple";

const ToolGuide = () => {
  const [selectedComponent, setSelectedComponent] = useState(<Introduction />);
  const [selectedItem, setSelectedItem] = useState("Introducción");
  const [principlesDropdown, setPrinciplesDropdown] = useState(false);

  const location = useLocation();
  const { data: userDocumentStatus } = useQueryGetUserDocumentStatus();
  const { data: listOfPrinciples } = useQueryGetListOfPrinciple();

  const containerRef = useRef(null);

  useEffect(() => {
    if (
      location?.state?.selectedOption?.name ===
      "Guía de herramienta huella de comunicación"
    ) {
      setSelectedComponent(<Introduction />);
      setSelectedItem("Introducción");
    } else if (
      location?.state?.selectedOption?.name === "Información de la empresa"
    ) {
      setSelectedComponent(<CompanyInfo />);
      setSelectedItem("Información de la empresa");
    } else if (location?.state?.selectedOption?.name === "Histórico de data") {
      setSelectedComponent(<DataHistory />);
      setSelectedItem("Histórico de data");
    }
  }, [location?.state?.selectedOption]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [selectedComponent]);

  const handleItemPrinciples = (item) => {
    setSelectedItem(item?.title);
    setSelectedComponent(<SinglePrinciple item={item} />);
  };
  const handleItemClick = (item) => {
    setSelectedItem(item);
    switch (item) {
      case "Introducción":
        setSelectedComponent(<Introduction />);
        break;
      case "Objetivos":
        setSelectedComponent(<Objectives />);
        break;
      case "Principios":
        setSelectedComponent(
          <Principles listOfPrinciples={listOfPrinciples} />
        );
        break;
      case "Metodología y guía":
        setSelectedComponent(<MethodologyGuide />);
        break;
      case "Glosario":
        setSelectedComponent(<Glossary />);
        break;
      case "Condiciones de uso":
        setSelectedComponent(<TermsOfUse />);
        break;
      case "Información de la empresa":
        setSelectedComponent(
          <CompanyInfo userDocumentStatus={userDocumentStatus} />
        );
        break;
      case "Histórico de data":
        setSelectedComponent(<DataHistory />);
        break;
      default:
        setSelectedComponent(null);
        break;
    }
  };
  const handlePagesNavigation = () => {
    if (selectedItem === "Introducción") {
      setSelectedComponent(<Objectives />);
      setSelectedItem("Objetivos");
    } else if (selectedItem === "Objetivos") {
      setSelectedComponent(<Principles listOfPrinciples={listOfPrinciples} />);
      setSelectedItem("Principios");
    } else if (selectedItem === "Principios") {
      setSelectedComponent(<MethodologyGuide />);
      setSelectedItem("Metodología y guía");
    } else if (selectedItem === "Metodología y guía") {
      setSelectedComponent(<Glossary />);
      setSelectedItem("Glosario");
    } else if (selectedItem === "Glosario") {
      setSelectedComponent(<TermsOfUse />);
      setSelectedItem("Condiciones de uso");
    } else if (selectedItem === "Condiciones de uso") {
      setSelectedComponent(
        <CompanyInfo userDocumentStatus={userDocumentStatus} />
      );
      setSelectedItem("Información de la empresa");
    } else if (selectedItem === "Información de la empresa") {
      setSelectedComponent(<Introduction />);
      setSelectedItem("Introducción");
    } else if (selectedItem === "Cumplimiento") {
      const principle = listOfPrinciples.data.data.find(
        (principle) => principle.title === "Autorregulación publicitaria"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Autorregulación publicitaria");
    } else if (selectedItem === "Autorregulación publicitaria") {
      const principle = listOfPrinciples.data.data.find(
        (principle) =>
          principle.title === "Sostenibilidad y propósito de la marca"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Sostenibilidad y propósito de la marca");
    } else if (selectedItem === "Sostenibilidad y propósito de la marca") {
      const principle = listOfPrinciples.data.data.find(
        (principle) => principle.title === "Cadena de producción publicitaria"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Cadena de producción publicitaria");
    } else if (selectedItem === "Cadena de producción publicitaria") {
      const principle = listOfPrinciples.data.data.find(
        (principle) =>
          principle.title ===
          "Adecuada representación de género en la comunicación comercial"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem(
        "Adecuada representación de género en la comunicación comercial"
      );
    } else if (
      selectedItem ===
      "Adecuada representación de género en la comunicación comercial"
    ) {
      const principle = listOfPrinciples.data.data.find(
        (principle) => principle.title === "Ecosistema digital"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Ecosistema digital");
    } else if (selectedItem === "Ecosistema digital") {
      const principle = listOfPrinciples.data.data.find(
        (principle) =>
          principle.title ===
          "Comunicación responsable a través de influenciadores"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Comunicación responsable a través de influenciadores");
    } else if (
      selectedItem === "Comunicación responsable a través de influenciadores"
    ) {
      const principle = listOfPrinciples.data.data.find(
        (principle) =>
          principle.title === "Protección de niños, niñas, adolescentes"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Protección de niños, niñas, adolescentes");
    } else if (selectedItem === "Protección de niños, niñas, adolescentes") {
      const principle = listOfPrinciples.data.data.find(
        (principle) => principle.title === "Cumplimiento"
      );
      setSelectedComponent(<SinglePrinciple item={principle} />);
      setSelectedItem("Cumplimiento");
    } else {
      console.warn("Unknown page: ", selectedItem);
    }
  };

  return (
    <Box sx={{ ml: "344px" }}>
      <div className="tool-guide">
        <div className="sidebar" style={{ flex: "0 0 21%" }}>
          <ul
            style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}
          >
            <li
              className={
                selectedItem === "Introducción" ? "selected-item" : "list-text"
              }
              onClick={() => handleItemClick("Introducción")}
            >
              <img src="/intro.png" alt="intro" className="list-image" />
              Introducción
            </li>
            <li
              className={
                selectedItem === "Objetivos" ? "selected-item" : "list-text"
              }
              onClick={() => handleItemClick("Objetivos")}
            >
              <img src="/goal-alt.png" alt="goal-alt" className="list-image" />
              Objetivos
            </li>
            <li
              className={
                selectedItem === "Principios" ? "selected-item" : "list-text"
              }
              onClick={() => handleItemClick("Principios")}
            >
              <img src="/law.png" alt="law" className="list-image" />
              Principios
            </li>
            <li
              className={
                selectedItem === "Metodología y guía"
                  ? "selected-item"
                  : "list-text"
              }
              onClick={() => handleItemClick("Metodología y guía")}
            >
              <img
                src="/metodology.png"
                alt="metodology"
                className="list-image"
              />
              Metodología y guía
            </li>
            <li
              className={
                selectedItem === "Glosario" ? "selected-item" : "list-text"
              }
              onClick={() => handleItemClick("Glosario")}
            >
              <img
                src="/translate.png"
                alt="translate"
                className="list-image"
              />
              Glosario
            </li>
            <li
              className={
                selectedItem === "Condiciones de uso"
                  ? "selected-item"
                  : "list-text"
              }
              onClick={() => handleItemClick("Condiciones de uso")}
            >
              <img
                src="/checklist.png"
                alt="checklist"
                className="list-image"
              />
              Condiciones de uso
            </li>
            <li
              className={
                selectedItem === "Información de la empresa"
                  ? "selected-item"
                  : "list-text"
              }
              onClick={() => handleItemClick("Información de la empresa")}
            >
              <img src="/business.png" alt="business" className="list-image" />
              Información de la empresa
            </li>
          </ul>
          <div
            style={{
              fontSize: "20px",
              margin: "15px 0px",
            }}
          >
            Anexos por principio{" "}
            <img
              style={{
                width: "25px",
                padding: "0px 0px 0px 10px",
                cursor: "pointer",
              }}
              src="/arrow-down.png"
              alt="arrow"
              onClick={() => setPrinciplesDropdown(!principlesDropdown)}
            />
          </div>
          {principlesDropdown && (
            <>
              <ul>
                {listOfPrinciples?.data?.data.map((principle, index) => (
                  <li
                    key={index}
                    className={
                      selectedItem === principle.title
                        ? "selected-item"
                        : "list-text"
                    }
                    onClick={() => handleItemPrinciples(principle)}
                  >
                    <img
                      src={principle?.icon}
                      alt={principle.title}
                      className="list-image"
                    />
                    {principle.title}
                  </li>
                ))}
              </ul>
            </>
          )}{" "}
          <ul>
            {" "}
            <li
              className={
                selectedItem === "Histórico de data"
                  ? "selected-item"
                  : "list-text"
              }
              onClick={() => handleItemClick("Histórico de data")}
            >
              <img
                src="/history_data.png"
                alt="history_data"
                className="list-image"
              />
              Histórico de data
            </li>
          </ul>
        </div>

        <div
          className="main-content"
          style={{
            flex: "0 0 70%",
          }}
        >
          <div>
            <img src="/header-toolguide.jpg" alt="header" />
          </div>
          <div
            ref={containerRef}
            style={{
              height: "70vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {selectedComponent}
          </div>
        </div>
      </div>
      <div className="tool-guide_button-container">
        <button className="tool-guide_button" onClick={handlePagesNavigation}>
          {" "}
          SIGUIENTE
        </button>
      </div>
    </Box>
  );
};

export default ToolGuide;
