import React, { useState } from "react";
import PdfResults from "../Dashboard/Search/PdfResults";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendFileDialog from "../Global/SendFileDialog";
import { useQuerySendFileOnEmail } from "../../hooks/react-query/useData";

const PdfDisplayFlow = ({
  downloadPdf,
  pdfFileUrl,
  pdfBottomBtnStyles,
  docId,
}) => {
  const [open, setOpen] = useState("");
  const [userEmail, setUserEmail] = useState("");

  let { refetch: sendFileOnEmail } = useQuerySendFileOnEmail({
    email: userEmail,
    doc_id: docId,
  });

  const handeClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <SendFileDialog
        open={open}
        setOpen={setOpen}
        sendFileOnEmail={sendFileOnEmail}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        handeClose={handeClose}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        <PdfResults pdfSource={pdfFileUrl} />
      </Box>
      <Box
        sx={{
          ...pdfBottomBtnStyles,
        }}
      >
        <Button
          variant="downloadNow"
          sx={{ display: "flex", columnGap: "5px" }}
          onClick={() => downloadPdf(pdfFileUrl)}
        >
          DESCARGAR AHORA
          <Box
            className="pdf-btn-img-container"
            component="img"
            src="/downloadIcon.png"
            sx={{ width: "25px", height: "20px" }}
          />
        </Button>

        <Button
          variant="shareMail"
          sx={{
            display: "flex",
            columnGap: "5px",
            background: "black !important",
          }}
          onClick={() => setOpen(true)}
        >
          COMPARTIR CORREO
          <Box
            className="pdf-btn-img-container"
            component="img"
            src="/messageIcon.png"
            sx={{ width: "25px", height: "20px" }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default PdfDisplayFlow;
