import * as React from "react";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

function MyFormHelperText({ formHelperText, helperTextStyles }) {
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    return formHelperText;
  }, [focused]);

  return (
    <FormHelperText sx={{ color: "white", ...helperTextStyles }}>
      {helperText}
    </FormHelperText>
  );
}

export default function UseFormControl({
  helperText,
  placeholder,
  sx,
  helperTextStyles,
  fieldBackgroundColor,
  register,
  validations,
  name,
  errors,
  type,
  value,
  disabled,
}) {
  const { ref, ...inputProps } = register(
    value != null || value != undefined ? name : "test",
    {
      ...validations,
      value: value,
    }
  );
  return (
    <Box autoComplete="off" sx={{ ...sx }}>
      <MyFormHelperText
        formHelperText={helperText}
        helperTextStyles={helperTextStyles}
      />

      <FormControl
        sx={{
          width: "100%",
          background: fieldBackgroundColor ? fieldBackgroundColor : "white",
          border: "1px solid #EAEAEA",
          borderRadius: "5px",
        }}
      >
        <OutlinedInput
          placeholder={placeholder}
          error={errors && !!errors[name]}
          name={name && name}
          type={type && type}
          //   defaultValue={value}
          //   value={value}
          {...inputProps}
          inputRef={ref}
          disabled={disabled ? disabled : false}
        />
      </FormControl>
      {errors && errors[name]?.message && (
        <span style={{ fontSize: "12px" }}>{errors[name]?.message}</span>
      )}
    </Box>
  );
}
