import React from "react";

const Objectives = () => {
  return (
    <div className="main-container">
      <div>
        <div
          className="description-font objective-body"
          style={{ display: "flex" }}
        >
          <div
            style={{
              flex: "0 0 40%",
              padding: "20px 40px",
            }}
          >
            <p className="description-font" style={{ fontWeight: "bolder" }}>
              {" "}
              Objetivo de la herramienta
            </p>
            La Herramienta de Huella de Publicidad Responsable es una iniciativa
            de apoyo práctica y de uso sencillo para ayudar a los anunciantes a
            (i) evaluar su gestión y estrategia de publicidad en función de la
            responsabilidad, autorregulación y la sostenibilidad; y (ii) medir y
            comunicar su compromiso de publicidad responsable con los diferentes
            stakeholders, basándose en el respeto por los derechos del
            consumidor, promoviendo una adecuada representación de género y no
            discriminación, y siendo un actor activo en la construcción de un
            mundo más sostenible.
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "0 0 60%",
              borderLeft: "1px solid",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid",
                padding: "20px  20px 40px 30px",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    marginTop: "40px",
                    marginRight: "18px",
                  }}
                  src="/goal.png"
                  alt="goal"
                />{" "}
                <div>
                  <p
                    className="description-font"
                    style={{ fontWeight: "bolder" }}
                  >
                    {" "}
                    Objetivos específicos{" "}
                  </p>
                  <p>
                    1) Establecer indicadores que les permitan a los anunciantes
                    valorar, cuestionar, reflexionar e implementar estrategias
                    de publicidad responsable, que en todo caso ayudará en la
                    reducción de riesgos, identificación de nuevas
                    oportunidades, captura y retención de clientes, atracción de
                    inversiones y en general ser rentables de manera sostenible.
                  </p>
                </div>
              </div>
            </div>
            <div className="objective-body" style={{ display: "flex" }}>
              <div
                style={{
                  borderRight: "1px solid",
                  flex: "0 0 50%",
                  display: "flex",
                  padding: "20px 30px 30px 30px",
                }}
              >
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "18px",
                  }}
                  src="/goal.png"
                  alt="goal"
                />{" "}
                <p>
                  2) Reconocer las diferentes iniciativas en publicidad
                  responsable como un conjunto de buenas prácticas que buscan ir
                  hacia un escenario o una industria cada vez más madura,
                  responsable y consciente de su rol en la construcción de una
                </p>
              </div>
              <div
                style={{
                  flex: "0 0 50%",
                  display: "flex",
                  padding: "20px 30px 30px 30px",
                }}
              >
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "18px",
                  }}
                  src="/goal.png"
                  alt="goal"
                />{" "}
                <p>
                  3) Abrir las puertas para una discusión constante que ayude a
                  los anunciantes a establecer prácticas y estándares
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="description-font"
          style={{
            padding: "20px 40px ",
            borderTop: "1px solid",
          }}
        >
          En este sentido, esta herramienta consiste en la aplicación de un
          cuestionario de 68 preguntas*, agrupadas en 8 principios que recogen
          las buenas prácticas en materia de publicidad y representan el
          compromiso de las compañías anunciantes afiliadas a la Anda en cuanto
          al ejercicio responsable de su publicidad:
          <p style={{ paddingTop: "20px" }}>
            *Estas preguntas indagan sobre la gestión realizada en el año
            inmediatamente anterior, es decir el año 2023.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Objectives;
