import * as React from "react";

import Router from "./routes";
import Layout from "./layout";
import "./styles/style.css";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Layout>
      <Toaster position="top-center" reverseOrder={false} />
      <Router />
    </Layout>
  );
}
