import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { downloadPdf } from "../../utils";
import { useLocation } from "react-router-dom";
import Alert from "../Global/Alert";
import LoadingDots from "../Global/LoadingDots";
import SearchResults from "../Dashboard/Search/SearchResults";
import {
  dashboardIntroContent,
  dashboardIntroLowerContent,
  dashboardIntroMiddleContent,
  noCategoryAndSubcategoryDialogueContent,
} from "../../mock_data";

import SearchBar from "../Dashboard/Search/SearchBar";
import StaticChat from "../Dashboard/StaticChat";
import { useSearchParams } from "react-router-dom";
import { deepOrange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import andaLogo from "../../assets/user-anda.svg";

import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";

import {
  filtersTextDashboardStyles,
  introTextDashboardStyles,
  pdfBottomBtnStyles,
  drawerStyles,
  drawerSmStyles,
  avatarContainerStyles,
  searchBarAndPdfBoxContainerStyles,
  filterIconStyles,
  staticChat,
} from "../../jsxStyles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ReactGA from "react-ga4";
import List from "@mui/material/List";

import { Outlet } from "react-router-dom";
import PdfDisplayFlow from "../Global/PdfDisplayFlow";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  useQueryGetAllCategories,
  useQueryLogoutUser,
  useQueryGetSearchedData,
  useQueryVerifyUserToken,
} from "../../hooks/react-query/useData";
import bookmarkIcon from "../../assets/StreamlineManualBook.png";
import introCover from "../../assets/introduccion-bg.jpg";

import { useTheme, useMediaQuery } from "@mui/material";
import AlertDialog from "../Global/Alert";

// Define drawer width and height
const drawerWidth = 335;
const drawerHeight = 96;

// Define the ResponsiveDrawer component
function ResponsiveDrawer(props) {
  // Extract necessary data and set initial states
  const resizeTheme = useTheme();
  const location = useLocation();
  const mediumLaptops = useMediaQuery(resizeTheme.breakpoints.down("lg"));
  const [searchedString, setSearchedString] = useState("");
  const [displayPdf, setDisplayPdf] = useState(false);
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [docId, setDocId] = useState("");
  const chatPlaceholder = JSON.parse(localStorage.getItem("chatPlaceholder"));
  // Send pageview to Google Analytics on component mount
  useEffect(() => {
    ReactGA._gaCommandSendPageview(`${location.pathname + location.search}`, {
      username: localStorage.getItem("username"),
      profilePicture: localStorage.getItem("imageURL"),
    });
  }, [location]);

  // Fetch searched data using react-query
  const {
    refetch: getSearchedData,
    isLoading,
    data: searchedData,
  } = useQueryGetSearchedData({
    question: searchedString,
  });

  // Fetch all categories data using react-query
  const { data: allCategories } = useQueryGetAllCategories();

  // Fetch logout user data using react-query
  const { refetch: logOutUser } = useQueryLogoutUser({});

  // Fetch user token verification data using react-query
  const { data: userTokenVerification, error: tokenValidationError } =
    useQueryVerifyUserToken();

  // Extract search parameters
  const [searchParams] = useSearchParams();

  // Initialize navigation hook
  let navigate = useNavigate();

  // Function to sign out user based on token expiration
  const signOutUser = (tokenExpired) => {
    if (tokenExpired) {
      removeTokenAndNavigate();
      return;
    }
    logOutUser();
    removeTokenAndNavigate();
  };

  // Function to handle profile navigation
  const profileHandler = () => {
    navigate("/dashboard/edit");
  };

  // Function to remove token and navigate
  const removeTokenAndNavigate = () => {
    localStorage.removeItem("auth_token");
    navigate("/");
  };

  // Extract current pathname
  const { pathname } = useLocation();

  // Extract window from props
  const { window } = props;

  // Set initial state for mobile drawer
  const [mobileOpen, setMobileOpen] = useState(false);

  // Set initial state for alert dialog
  const [open, setOpen] = useState(false);

  // Toggle mobile drawer function
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Open alert dialog function
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Close alert dialog function
  const handleClose = () => {
    setOpen(false);
  };

  // Function to initiate search from backend
  const searchFromBackend = () => {
    getSearchedData();
  };

  // useEffect to handle user token expiration and validation error
  useEffect(() => {
    if (userTokenVerification?.data?.isExpired) {
      signOutUser(true);
    }
    if (
      tokenValidationError?.response?.data &&
      tokenValidationError.response.data.detail.includes("Invalid token")
    ) {
      signOutUser(true);
    }
  }, [userTokenVerification, tokenValidationError]);

  // Get user full name
  const fullName = localStorage.getItem("fullName");

  // Drawer component JSX
  const drawer = (
    <Box sx={{ pt: "54px", pl: "33px" }}>
      <AlertDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        searchFromBackend={searchFromBackend}
        content={noCategoryAndSubcategoryDialogueContent}
        btnText="Elegir la categoría"
      />
      {/* ANDA logo */}
      <Box
        component="img"
        src={require("../../assets/logo@2x.png")}
        sx={{ cursor: "pointer", width: "57%" }}
        onClick={() => {
          navigate("/dashboard");
          setMobileOpen(false);
        }}
      />
      {/* Categories text and filter icon */}
      <Typography
        sx={{
          ...filtersTextDashboardStyles,
        }}
      >
        Categorías
        <Box
          component="img"
          src="/filterIcon.png"
          sx={{
            ...filterIconStyles,
          }}
        />
      </Typography>
      {/* List of categories */}
      <List>
        {allCategories &&
          Object.keys(allCategories?.data?.data).length > 0 &&
          allCategories?.data?.data.map((text, index) => (
            <>
              <Box
                sx={{ display: "flex", columnGap: "21px", cursor: "pointer" }}
                key={index}
                onClick={() => {
                  if (searchParams.get("id") == text.id) {
                    searchParams.delete("id");
                    navigate(`/dashboard`);
                    setMobileOpen(false);
                  } else {
                    navigate(`/dashboard/${text.name}?id=${text.id}`);
                    setMobileOpen(false);
                  }
                }}
              >
                <Box
                  component="img"
                  src={text?.logo}
                  sx={{ width: "25px", height: "25px" }}
                />
                <p
                  style={{
                    maxWidth: "204px",
                    margin: "0",
                    marginBottom: "28px",
                    fontSize: "18px",
                    fontFamily: "Helvetica",
                    color:
                      searchParams.get("id") == text.id && !!text.color_code
                        ? text.color_code
                        : "white",
                  }}
                >
                  {text.name}
                </p>
              </Box>
            </>
          ))}
        {mobileOpen ? (
          <Button
            sx={{
              textTransform: "capitalize",
              color: "#E8112A",
            }}
            onClick={() => signOutUser()}
          >
            Cerrar sesión
          </Button>
        ) : null}
      </List>
      <Box width="90%" height={50} display={"flex"} flexDirection={"column"}>
        <Button
          component="a"
          href="https://youtu.be/p-TxghunZfI"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="inherit"
          style={{
            display: "inline-flex",
            backgroundColor: "#F9B000",
            borderRadius: "20px",
            fontSize: "14px",
            fontFamily: "Helvetica",
            width: "80%",
            alignItems: "center",
            height: "90%",
            letterSpacing: 0.7,
            textAlign: "center",
            fontWeight: "lighter",
            margin: "15px 0px",
            textTransform: "none",
          }}
        >
          Grabaciones ANDA
        </Button>
        <Button
          component="a"
          href="https://drive.google.com/file/d/1apTK9hUfqvEO-QpDnQI33IvtnBSUz_8n/view?usp=drive_link"
          target="_blank"
          rel="noopener noreferrer"
          variant="contained"
          color="inherit"
          style={{
            display: "inline-flex",
            backgroundColor: "#F9B000",
            borderRadius: "20px",
            fontSize: "14px",
            fontFamily: "Helvetica",
            width: "80%",
            alignItems: "center",
            height: "90%",
            letterSpacing: 0.7,
            textAlign: "center",
            fontWeight: "lighter",
            margin: "15px 0px",
            textTransform: "none",
          }}
          endIcon={
            <Box
              component={"img"}
              src={bookmarkIcon}
              alt="book-mark"
              sizes="large"
            ></Box>
          }
        >
          Ver manual de ayuda
        </Button>
      </Box>
    </Box>
  );

  // Determine container based on window presence
  const container =
    window !== undefined ? () => window().document.body : undefined;

  // Main component JSX
  return (
    <>
      {/* Main container with CSS baseline */}
      <Box sx={{ display: "block", minHeight: "100vh" }}>
        <CssBaseline />
        {/* App bar with navigation */}
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: { sm: `calc(100% -${drawerHeight}px)` },
            ml: { sm: `${drawerWidth}px` },
            mb: { sm: `${drawerHeight}px` },
            background: "#f8f8f8",
            boxShadow: "none",
          }}
        >
          {/* Navbar */}
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* Hamburger icon for mobile */}

            {/* User welcome message and profile/logout button */}
            <Typography
              variant="welcomeUser"
              style={{
                fontSize:
                  mediumLaptops || fullName.length >= 90 ? "1rem" : "1.5rem",
              }}
            >
              {location.pathname === "/dashboard/tool_guide" ? (
                <div
                  onClick={() => {
                    navigate("/dashboard/Sostenibilidad?id=6");
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src="/left-arrow.png"
                    alt="Icon"
                    style={{
                      marginRight: "10px",
                      marginBottom: "5px",
                      width: "25px",
                    }}
                  />
                  <p style={{ fontSize: "30px", fontWeight: "bolder" }}>
                    {" "}
                    Guia de herramienta huella de comunicacion
                  </p>
                </div>
              ) : fullName === "null null" ? (
                ""
              ) : (
                `Bienvenido ${fullName}`
              )}
              {/* {fullName === "null null" ? "" : `Bienvenido ${fullName}`} */}
            </Typography>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* Check for specific pathname for special user content */}
              {pathname.includes("naoaoc") ? (
                <Typography
                  sx={{ color: "black", display: "flex", columnGap: "16px" }}
                >
                  Acceso afiliados
                  <Box
                    component="img"
                    src="/contact.png"
                    sx={{ width: "20px" }}
                  />
                </Typography>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{
                        mr: 2,
                        display: { md: "none" },
                        width: "100%",
                      }}
                    >
                      {/* <p>M Icon</p> */}
                      <div className="mobile-menu-button">
                        <HorizontalSplitIcon />
                      </div>
                    </IconButton>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "8px",
                      mt: "20px",
                    }}
                  >
                    {/* User Avatar */}
                    <Avatar
                      src={localStorage.getItem("imageURL")}
                      sx={{
                        bgcolor: deepOrange[500],
                        height: "59px",
                        width: "59px",
                      }}
                      onClick={profileHandler}
                    >
                      {localStorage.getItem("username").slice(0, 1)}
                    </Avatar>
                    <Box>
                      {/* User Name and Role */}
                      <Typography sx={{ color: "#050107" }}>
                        {localStorage.getItem("username")}
                      </Typography>
                      <Typography sx={{ fontSize: "11px", color: "#050107" }}>
                        Afiliado
                      </Typography>
                    </Box>
                    {/* Logout button */}
                    <Button
                      sx={{
                        textTransform: "capitalize",
                        color: "#E8112A",
                        margin: "0px 0px 30px 25px",
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "block",
                          lg: "block",
                        },
                      }}
                      onClick={() => signOutUser()}
                    >
                      Cerrar sesión
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </Toolbar>
        </AppBar>
        {/* Sidebar navigation drawer */}
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* Mobile drawer */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              ...drawerStyles(drawerWidth),
            }}
          >
            {drawer}
          </Drawer>
          {/* Permanent drawer for larger screens */}
          <Drawer
            variant="permanent"
            sx={{
              ...drawerSmStyles(drawerWidth),
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          {/* Toolbar space */}
          <Toolbar />

          {/* Check for specific pathname to render content */}
          {pathname === "/dashboard" && (
            <Box>
              {!chatPlaceholder ? (
                <Box
                  sx={{ ...searchBarAndPdfBoxContainerStyles }}
                  className="introduction-upper-card"
                >
                  {/* Searchbar section starts */}
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={{ xs: "100%", md: "100%", lg: "100%" }}
                    height="50%"
                    mb={6}
                  >
                    {/* Search bar container */}
                    <Box
                      sx={{
                        backgroundImage: `url(${introCover})`,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderRadius: "8px",
                        border: "1px solid #707070",
                        padding: "20px",
                      }}
                    >
                      {/* Welcome message */}
                      <Typography
                        variant="p"
                        color="#FFFFFF"
                        component="p"
                        fontFamily="Helvetica"
                        fontSize={{ xs: 25, md: 25, lg: 60 }}
                        fontWeight="bold"
                        height={{ xs: 40, md: 50, lg: 90 }}
                      >
                        Bienvenido a la plataforma ANDA
                      </Typography>
                      {/* Introduction text */}
                      <Typography
                        variant="p"
                        fontFamily="Helvetica"
                        fontSize={{ sm: 12, md: 12, lg: 23 }}
                        color="#FFFFFF"
                        letterSpacing={0.62}
                        marginBottom={{ sm: "15px", lg: "30px" }} // Adjust margin for different screen sizes
                        height={{ sm: "10px", md: "10px", lg: "37px" }} // Adjust height for different screen sizes
                      >
                        Únete a nuestra red: la comunidad asociativa más grande
                        de colombia
                      </Typography>

                      {/* Back button for PDF display */}
                      {displayPdf && pdfFileUrl && (
                        <Button
                          sx={{
                            textTransform: "capitalize",
                            backgroundColor: "#ffffff",
                            borderRadius: "10px",
                            marginBottom: "5px",
                            fontWeight: "normal",
                            "&:hover": {
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() => {
                            setPdfFileUrl("");
                            setDisplayPdf(false);
                          }}
                        >
                          <ArrowBackIcon sx={{ mr: "5px" }} />
                          Back
                        </Button>
                      )}

                      {/* Search bar component */}
                      <SearchBar
                        setSearchedString={setSearchedString}
                        searchFromBackend={handleClickOpen}
                        sx={{
                          mb: "15px",
                          width: {
                            sm: "100%",
                            md: "60%",
                            lg: "100%",
                          },
                          height: "12%",
                        }}
                      />
                    </Box>
                  </Box>
                  {/* Searchbar section ends */}

                  {/* Render loading dots, PDF display, or search results based on conditions */}
                  <Box
                    sx={{
                      width: {
                        sm: "80%",
                        md: "80%",
                        lg: "100%",
                      },
                    }}
                  >
                    {" "}
                    {isLoading ? (
                      <LoadingDots loaderText="Buscando Datos..." />
                    ) : displayPdf && pdfFileUrl ? (
                      <PdfDisplayFlow
                        downloadPdf={downloadPdf}
                        pdfFileUrl={pdfFileUrl}
                        pdfBottomBtnStyles={pdfBottomBtnStyles}
                        docId={docId}
                      />
                    ) : (
                      searchedData && (
                        <SearchResults
                          setDisplayPdf={setDisplayPdf}
                          searchedData={searchedData?.data?.data}
                          setPdfFileUrl={setPdfFileUrl}
                          stringifiedData={JSON.stringify(
                            searchedData?.data?.data
                          )}
                          setDocId={setDocId}
                        />
                      )
                    )}{" "}
                  </Box>

                  {/* Introduction section starts */}
                  {!searchedData && !isLoading && (
                    <Box
                      display="flex"
                      justifyContent={{
                        sm: "center",
                        md: "flexStart",
                        lg: "center",
                      }}
                      mb={4}
                      width={{ sm: "100%", md: "100%", lg: "100%" }}
                    >
                      {/* Introduction content */}
                      <Stack
                        spacing={1}
                        width={"69%"}
                        boxShadow={"31px 31px 0px #E30613"}
                        borderRadius={"10px"}
                      >
                        <Paper
                          elevation={2}
                          width={"80%"}
                          style={{ borderRadius: "10px" }}
                        >
                          <Stack
                            direction={"column"}
                            justifyContent={"left"}
                            padding={"19px 0px 33px 0px"}
                          >
                            <Stack
                              alignItems={"left"}
                              direction={"row"}
                              spacing={0.65}
                              fontSize={{ sm: 26, md: 26, lg: 32 }}
                              padding={"5px 26px 30px 26px"}
                            >
                              {/* ANDA logo for introduction */}
                              <Stack direction={"column"}>
                                <Typography
                                  variant="p"
                                  fontWeight={"bold"}
                                  component={"p"}
                                  height={"25px"}
                                >
                                  Introducción
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack direction={"row"} alignItems={"left"}>
                              <Stack spacing={2} sx={{ paddingLeft: "27px" }}>
                                {/* Introduction text */}
                                <Typography
                                  textAlign={"left"}
                                  width={"86%"}
                                  fontSize={{ sm: 14, md: 14, lg: 18 }}
                                >
                                  {dashboardIntroContent}
                                </Typography>
                                <Typography textAlign={"left"} width={"87%"}>
                                  {dashboardIntroMiddleContent}
                                </Typography>
                                <Typography textAlign={"left"} width={"87%"}>
                                  {dashboardIntroLowerContent}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </Paper>
                      </Stack>
                    </Box>
                  )}
                  {/* Introduction section ends */}
                </Box>
              ) : (
                <Box sx={{ ...staticChat }} className="static-chat-screen">
                  {/* Render static chat component */}
                  <StaticChat />
                </Box>
              )}
            </Box>
          )}
        </Box>
        {/* Render nested routes */}
        <Outlet context={{ categories: allCategories?.data?.data }} />
      </Box>
    </>
  );
}

export default ResponsiveDrawer;
