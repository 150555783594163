import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import { infoIconBlackStyles } from "../../../jsxStyles";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { editProfileFunctionalBtnStyles } from "../../../jsxStyles";

import CustomTextField from "../../Global/CustomTextField";
import CustomTextFieldData from "../../Global/CustomTextFieldDisplayData";
import SimpleDialog from "../../Global/SimpleDialog";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import axios from "axios";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import FileUpload from "./FileUpload";

// ProfileInfoForm component definition
const ProfileInfoForm = ({
  allUserInfo,
  updateUserDetails,
  setUser,
  user,
  onSubmit,
  isLoading,
  setSelectedImage,
  selectedImage,
}) => {
  const navigate = useNavigate();

  const btnRef = useRef();

  // React Hook Form setup
  const {
    register,
    formState: { errors },
    handleSubmit: userInfoForm,
  } = useForm({});

  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");

  const buttonStyle = {
    fontSize: "11px",
    cursor: "pointer",
    color: "#2D2E83",
    textDecoration: "underline",
    height: "16px",
    marginLeft: "10px",
  };

  // Close the confirmation dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Save changes to user details
  const handleSaveChanges = (data) => {
    onSubmit(data);
  };

  // Handle password change
  const handlePassword = (e) => {
    e.preventDefault();
    // Toggle the confirmation dialog
    if (!open) {
      setOpen(true);
      return;
    }

    setOpen(false);

    const data = {
      password: password,
      confirm_password: confirmPassword,
    };

    if (password === confirmPassword) {
      if (password?.length < 6) {
        // Display an error toast if the password is too short
        toast.dismiss();
        toast.error("La longitud de la contraseña debe ser mayor que 5");
      } else {
        // Continue with the password change logic
        axios
          .patch(`${process.env.REACT_APP_SERVER_URL}/change-password/`, data, {
            headers: {
              Authorization: `Token ${localStorage.getItem("auth_token")}`,
            },
          })
          .then((response) => {
            // Remove authentication token and navigate to the home page
            localStorage.removeItem("auth_token");
            navigate("/");
          })
          .catch((error) => {
            // console.log(error);
            // Display an error toast with a message or a generic error message
            toast.error(
              error?.response?.data?.message
                ? error?.response?.data?.message
                : "Algo salió mal. Por favor, vuelva a intentarlo"
            );
          });
      }
    } else {
      // Display an error toast if passwords do not match
      toast.dismiss();
      toast.error("Contraseña y Confirmar contraseña no coinciden.");
    }
  };

  // Cancel button click handler
  const handleCancel = () => {
    navigate("/dashboard");
  };

  return (
    <Stack>
      <Stack>
        {/* Paper component for styling */}
        <Paper
          sx={{
            padding: "32.69px 42.98px 0 30.13px",
            borderRadius: "none",
            boxShadow: "0px 4px 14px rgba(0,0,0,0.2),20px 20px 0px #E8112A",
          }}
        >
          <Stack direction={"row"} justifyContent={"center"}>
            {/* FileUpload component for handling image upload */}
            <FileUpload
              allUserInfo={allUserInfo}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          </Stack>
          <Box>
            <Stack
              direction={"row"}
              spacing={1.25}
              alignItems={"center"}
              justifyContent={"left"}
              mb={1}
            >
              <Box
                component="img"
                src="/infoIconBlack.png"
                sx={{
                  ...infoIconBlackStyles,
                }}
              />
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                Información de perfil
              </Typography>
            </Stack>
            <form onSubmit={userInfoForm}>
              <Box sx={{ marginBottom: "42px" }}>
                <Grid container columnSpacing={2} rowSpacing={2}>
                  {/* CustomTextFieldData components for displaying user details */}
                  <Grid item lg={4} sm={12}>
                    <CustomTextFieldData
                      helperTextStyles={{ color: "black", fontSize: "17px" }}
                      sx={{ width: "95%" }}
                      fieldBackgroundColor={"none"}
                      helperText="Nombre de pila"
                      value={allUserInfo?.data?.data?.first_name}
                      register={register}
                      name="first_name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={12}>
                    <CustomTextFieldData
                      helperTextStyles={{ color: "black", fontSize: "17px" }}
                      sx={{ width: "95%" }}
                      fieldBackgroundColor={"none"}
                      helperText="Apellidos"
                      value={allUserInfo?.data?.data?.last_name}
                      register={register}
                      name="last_name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={12}>
                    <CustomTextFieldData
                      helperTextStyles={{ color: "black", fontSize: "17px" }}
                      fieldBackgroundColor={"none"}
                      sx={{ width: "95%" }}
                      helperText="Correo"
                      value={allUserInfo?.data?.data?.email}
                      register={register}
                      name="email"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item lg={4} sm={12}>
                    <CustomTextFieldData
                      helperTextStyles={{ color: "black", fontSize: "17px" }}
                      fieldBackgroundColor={"none"}
                      sx={{ width: "95%" }}
                      value={
                        allUserInfo?.data?.data?.phone_number === null
                          ? ""
                          : allUserInfo?.data?.data?.phone_number
                      }
                      helperText="Teléfono de contacto"
                      register={register}
                      name="phone_number"
                      type="tel"
                    />
                  </Grid>

                  {allUserInfo?.data?.data?.companies.map(
                    (companyData, index) => (
                      <React.Fragment key={index}>
                        <Grid item lg={4} sm={12}>
                          <CustomTextFieldData
                            helperTextStyles={{
                              color: "black",
                              fontSize: "17px",
                            }}
                            fieldBackgroundColor={"none"}
                            sx={{ width: "95%" }}
                            helperText={"Nombre de empresa"}
                            disable
                            value={companyData.name}
                            register={register}
                            name={`name${index}`}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item lg={4} sm={12}>
                          <CustomTextFieldData
                            helperTextStyles={{
                              color: "black",
                              fontSize: "17px",
                            }}
                            fieldBackgroundColor={"none"}
                            sx={{ width: "95%" }}
                            helperText={"Cargo"}
                            disable
                            value={companyData.role}
                            register={register}
                            name={`cargo${index}`}
                            disabled={true}
                          />
                        </Grid>
                      </React.Fragment>
                    )
                  )}
                </Grid>
              </Box>

              {/* Change Password Form */}
              <div>
                <Box sx={{ paddingBottom: "50px" }}>
                  <Stack
                    direction={"row"}
                    spacing={1.25}
                    justifyContent={"left"}
                    alignItems={"center"}
                    mb={1}
                  >
                    <Box
                      component="img"
                      src="/settingsIcon.png"
                      sx={{
                        ...infoIconBlackStyles,
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}
                    >
                      Configuración avanzada
                    </Typography>
                  </Stack>
                  <Box marginBottom={"1%"}>
                    <Grid container rowSpacing={4} columnSpacing={4}>
                      {/* CustomTextField components for password change */}
                      <Grid item lg={4} sm={12}>
                        <CustomTextField
                          helperTextStyles={{
                            color: "black",
                            fontSize: "17px",
                          }}
                          sx={{ width: "95%" }}
                          fieldBackgroundColor={"none"}
                          helperText="Contraseña"
                          placeholder="**********"
                          register={register}
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item lg={4} sm={12}>
                        <CustomTextField
                          helperTextStyles={{
                            color: "black",
                            fontSize: "17px",
                          }}
                          sx={{ width: "95%" }}
                          fieldBackgroundColor={"none"}
                          helperText="Confirmar contraseña"
                          placeholder="**********"
                          register={register}
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* SimpleDialog component for confirmation */}
                  <SimpleDialog
                    open={open}
                    title="¿Estás seguro de que quieres cambiar la contraseña?"
                    content={
                      "Después de cambiar la contraseña, deberá iniciar sesión nuevamente."
                    }
                    btnRef={btnRef}
                    handleClose={handleClose}
                  />
                  {/* Link component for triggering password change */}
                  <Link
                    ref={btnRef}
                    style={buttonStyle}
                    onClick={handlePassword}
                  >
                    Cambiar contraseña
                  </Link>
                </Box>
              </div>
            </form>
          </Box>
        </Paper>
      </Stack>
      <Stack>
        {/* Box for edit profile functional buttons */}
        <Box
          sx={{
            ...editProfileFunctionalBtnStyles,
          }}
        >
          {/* Cancel button */}
          <Button variant="cancel" onClick={handleCancel}>
            CANCELAR
          </Button>
          {/* LoadingButton component for saving changes */}
          <LoadingButton
            type="submit"
            variant="saveChanges"
            loading={isLoading}
            onClick={userInfoForm(handleSaveChanges)}
            style={{ backgroundColor: "#E71029" }}
          >
            GUARDAR CAMBIOS
          </LoadingButton>
        </Box>{" "}
      </Stack>
    </Stack>
  );
};

export default ProfileInfoForm;
