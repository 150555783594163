import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const Introduction = () => {
  return (
    <div className="main-container">
      <div
        className="intro-container description-font"
        style={{ margin: "60px" }}
      >
        {" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Carousel>
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: "0",
                marginRight: "30px",
              }}
            >
              <iframe
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "48vh",
                }}
                src="https://www.youtube.com/embed/Kt9nUqWxX9k?rel=0&modestbranding=1&showinfo=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="intro-pic">
              <img
                src="/intro-page-img-landscape.jpg"
                style={{ maxWidth: "100%" }}
              />{" "}
            </div>
          </Carousel>{" "}
        </div>
        <div className="description-font intro-paragraph-with-img">
          <p>
            {" "}
            Estimado anunciante, Bienvenido a la Herramienta de Huella de
            Comunicación Comercial Responsable, iniciativa desarrollada por la
            ANDA en el 2017 con el apoyo de Deloitte y actualiza en el 2021 con
            el apoyo de ONU mujeres y USAID para el desarrollo del principio #5.
          </p>
        </div>
        <div
          className="description-font intro-paragraph-with-img"
          style={{ display: "flex", marginBottom: "40px" }}
        >
          <p>
            La presente iniciativa es un esfuerzo conjunto de los afiliados a la
            Asociación Nacional de Anunciantes de Colombia para:<br></br>
            <br></br>
            Contribuir, mediante una publicidad ética y responsable, en la
            construcción de modalidades de consumo y producción sostenible (ODS
            12), en una transformación social y cultural adecuada para la
            representación de género (ODS 5) en la comunicación comercial y
            adoptando medidas urgentes para combatir el cambio climático y sus
            efectos (ODS 13).
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="intro-img-in-paragraph"
            src="/genero.png"
            alt="genero"
          />{" "}
          <img
            className="intro-img-in-paragraph"
            // style={{ height: "100px",margin:"20px" }}
            src="/infinite.png"
            alt="infinite"
          />
          <img
            className="intro-img-in-paragraph"
            // style={{ height: "100px",margin:"20px" }}
            src="/Green-logo.png"
            alt="genero"
          />
        </div>
        <div className="description-font" style={{ marginBottom: "30px" }}>
          * La entidad de Naciones Unidas para la Igualdad de Género y el
          Empoderamiento de las Mujeres, ONU Mujeres, basandose en la visión de
          igualdad consagrada en la Carta de Naciones Unidas, trabaja para
          eliminar la discriminación en contra las mujeres y las niñas; por el
          empoderamiento de las mujeres, y para lograr la igualdad entre mujeres
          y hombres como socios y beneficiarios del desarrollo, en la
          realización de los derechos humanos, en las acciones comunitarias, en
          la paz y la seguridad. Al colocar los derechos de las mujeres como el
          eje central de su labor, ONU Mujeres lidera y coordina los esfuerzos
          del Sistema de Naciones Unidas para asegurar que los compromisos de
          igualdad y transversalidad de género se traduzcan en acciones en todo
          el mundo.
        </div>
        <div className="description-font" style={{ marginBottom: "30px" }}>
          En este sentido, ONU Mujeres en Colombia apoya el cumplimiento de
          estos compromisos internacionales, y ha apoyado a la Anda en la
          realización de la Guía de Autoregulación Publicitaria para la Adecuada
          Representación de Género en la Comunicación Comercial, lanzada el 4 de
          febrero de 2020, en donde 40 afiliados de la Anda se comprometieron a
          aplicar la guía para su gestión publicitaria. En concordancia, ONU
          Mujeres Colombia ha suministrado apoyo técnico para la realización de
          la presente herramienta con foco en la contribución del ODS 5 y el
          principio 5 para la adecuada representacion de género en la
          comunicación comercial.
        </div>
        <div className="description-font" style={{ marginBottom: "30px" }}>
          ** La construcción del principio 5 para promover la igualdad de género
          en la comunicación comercial fue posible gracias al generoso apoyo del
          pueblo de Estados Unidos a través de la Agencia de Estados Unidos para
          el Desarrollo Internacional (USAID) en el marco del Programa Superando
          la violencia contra las mujeres. Los contenidos son responsabilidad de
          la ANDA y ONU Mujeres y no reflejan necesariamente las opiniones de
          USAID o del Gobierno de Estados Unidos."
        </div>
      </div>
    </div>
  );
};

export default Introduction;
