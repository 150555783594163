import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, IconButton, Modal } from "@mui/material";
import { useQueryClient } from "react-query";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  useQueryGetFileHistory,
  useMutationDeleteHistory,
} from "../../hooks/react-query/useData";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

const DataHistory = () => {
  const queryClient = useQueryClient();
  const [age, setAge] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [fileHistory, setFileHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [page, setPage] = useState(1);
  const limit = 10; // Number of items per page
  const offset = (page - 1) * limit;
  const { data, isLoading } = useQueryGetFileHistory(offset, limit);
  const [pageCount, setPageCount] = useState(0);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFileId(null);
  };

  const { mutate: deleteHistory } = useMutationDeleteHistory(
    selectedFileId,
    handleCloseModal,
    queryClient
  );

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const truncateText = (text, maxLength = 20) => {
    return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
  };

  const handleDelete = async () => {
    if (!selectedFileId) return;
    deleteHistory();
  };

  const handleOpenDeleteModal = (id) => {
    setSelectedFileId(id);
    setIsModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="main-container ">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : error ? (
          <p>Error loading data</p>
        ) : (
          <div>
            <div
              className="history-table-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Instrumento</div>
              <div>Principio / Pregunta</div>
              <div>Fecha</div>
              <div>Encargado</div>
              <div></div>
            </div>

            <div className="history-data-container">
              {data?.data?.data?.results?.map((file) => (
                <div
                  className="history-table-body"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={file.id}
                >
                  <div>
                    <a
                      href={file.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {truncateText(file.file)}
                    </a>
                  </div>
                  <div>
                    {truncateText(file.principal)} /{" "}
                    {truncateText(file.question)}
                  </div>
                  <div>{new Date(file.created_at).toLocaleDateString()}</div>
                  <div>{file.created_by}</div>
                  <div>
                    <img
                      style={{ width: "20px", cursor: "pointer" }}
                      src="/dustbin.png"
                      alt="dustbin"
                      onClick={() => handleOpenDeleteModal(file.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="pagination-UI">
        <Stack spacing={2} style={{ marginTop: "20px" }}>
          <Pagination
            count={data?.data?.data?.pages}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "24px",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            <p>
              <b>Borrar historial</b>
            </p>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <hr />

          <div style={{ display: "flex" }}>
            <img
              style={{ width: "25px", height: "25px", marginRight: "5px" }}
              src="/intro.png"
              alt="info"
            />
            <p style={{ color: "grey", fontSize: "20px" }}>
              Realmente quieres borrarlo.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              className="question-modal-cancel-button"
              onClick={handleCloseModal}
            >
              CANCELAR
            </Button>
            <Button
              className="question-modal-save-button"
              onClick={handleDelete}
              disabled={isLoading}
            >
              BORRAR
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DataHistory;
