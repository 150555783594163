import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { button } from "./overrides/Button";
// import TextField from "./overrides/TextField";

// A custom theme for this app

const theme = createTheme({
  palette: {
    // color variants used throughout the application
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },

  typography: {
    color: "#000000",

    footer: {
      fontSize: "18px",
    },
    heroMain: { fontSize: "42px", display: "block" },
    heroSecondary: {
      fontSize: "22px",
      letterSpacing: "0.07px",
      display: "block",
      fontWeight: "normal",
    },
    formTitle: {
      color: "#F8F8F8",
      fontSize: "36px",
    },
    formSecondary: {
      color: "#FFFFFF",
      fontSize: "15px",
    },
    welcomeUser: {
      color: "#3B3A3E",
      fontFamily: "Helvetica",
    },
    searchResultsSecondary: {
      color: "#3B3A3E",
      fontWeight: "bold",
      fontSize: "18px",
    },
    intro: {
      fontSize: "32px",
      fontWeight: "bold",
      display: "block",
    },

    // variants from here
  },

  components: {
    ...button,
    // ...TextField,
  },
});

export default theme;
