import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  useQueryGetUserDocumentStatus,
  useQueryGetUserInfo,
} from "../../hooks/react-query/useData";
import axios from "axios";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";

const CompanyInfo = () => {
  const { data: userDocumentStatus, refetch: refetchUserDocumentStatus } =
    useQueryGetUserDocumentStatus();
  const { data: allUserInfo, refetch } = useQueryGetUserInfo();

  const [firstAcceptedFiles, setFirstAcceptedFiles] = useState([]);
  const [secondAcceptedFiles, setSecondAcceptedFiles] = useState([]);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [isSecondLoading, setIsSecondLoading] = useState(false);

  const [firstFileStatus, setFirstFileStatus] = useState(false);
  const [secondFileStatus, setSecondFileStatus] = useState(true);

  //commenting because we don't need the dropbox to be controlled by the API's response and adding default value to the states
  // useEffect(() => {
  //   if (userDocumentStatus?.data?.data?.count === 0) {
  //     setFirstFileStatus(true);
  //     setSecondFileStatus(true);
  //   } else if (userDocumentStatus?.data?.data?.count === 1) {
  //     setFirstFileStatus(false);
  //     setSecondFileStatus(true);
  //   } else if (userDocumentStatus?.data?.data?.count === 2) {
  //     setFirstFileStatus(false);
  //     setSecondFileStatus(false);
  //   }
  // }, [userDocumentStatus]);

  const handleUploadFile = async (file, setLoading, refetch) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/principal/upload/user/document/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${localStorage.getItem("auth_token")}`,
          },
        }
      );
      toast.success(response?.data?.message);
      refetch();
    } catch (error) {
      toast.error("No tienes permiso para subir archivos");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refetchUserDocumentStatus();

    if (firstAcceptedFiles.length > 0) {
      handleUploadFile(
        firstAcceptedFiles[0],
        setIsFirstLoading,
        refetchUserDocumentStatus
      );
      setFirstAcceptedFiles([]);
    }
  }, [firstAcceptedFiles]);

  useEffect(() => {
    refetchUserDocumentStatus();

    if (secondAcceptedFiles.length > 0) {
      handleUploadFile(
        secondAcceptedFiles[0],
        setIsSecondLoading,
        refetchUserDocumentStatus
      );
      setSecondAcceptedFiles([]);
    }
  }, [secondAcceptedFiles]);

  const {
    getRootProps: getFirstRootProps,
    getInputProps: getFirstInputProps,
    isFocused: isFirstFocused,
    isDragAccept: isFirstDragAccept,
    isDragReject: isFirstDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,
    onDrop: (acceptedFiles) => setFirstAcceptedFiles(acceptedFiles),
  });

  const {
    getRootProps: getSecondRootProps,
    getInputProps: getSecondInputProps,
    isFocused: isSecondFocused,
    isDragAccept: isSecondDragAccept,
    isDragReject: isSecondDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
    multiple: false,

    onDrop: (acceptedFiles) => setSecondAcceptedFiles(acceptedFiles),
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    margin: "20px 95px",
    borderWidth: 2,
    borderRadius: "12px",
    borderColor: "grey",
    backgroundColor: "white",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const firstStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFirstFocused ? focusedStyle : {}),
      ...(isFirstDragAccept ? acceptStyle : {}),
      ...(isFirstDragReject ? rejectStyle : {}),
    }),
    [isFirstFocused, isFirstDragAccept, isFirstDragReject]
  );

  const secondStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isSecondFocused ? focusedStyle : {}),
      ...(isSecondDragAccept ? acceptStyle : {}),
      ...(isSecondDragReject ? rejectStyle : {}),
    }),
    [isSecondFocused, isSecondDragAccept, isSecondDragReject]
  );

  return (
    <div className="main-container">
      <div className="input-container" style={{ marginTop: "20px" }}>
        <div className="input-group" style={{ width: "40%" }}>
          <p>Nombre responsable</p>
          <input
            className="company-info-inputs"
            type="text"
            placeholder="Juanjo Rodriguez"
            value={`${allUserInfo?.data?.data?.first_name} ${allUserInfo?.data?.data?.last_name}`}
          />
          <p>Correo electronico</p>
          <input
            className="company-info-inputs"
            type="text"
            placeholder="jrodriguez@company.co"
            value={allUserInfo?.data?.data?.email}
          />
        </div>
        <div className="input-group" style={{ width: "40%" }}>
          <p>Telefono</p>
          <input
            className="company-info-inputs"
            type="text"
            placeholder="+57 3205228585"
            value={allUserInfo?.data?.data?.phone_number}
          />
          <p>Empresa</p>
          <input
            className="company-info-inputs"
            type="text"
            placeholder="Company LTDA "
            value={allUserInfo?.data?.data?.companies[0]?.name}
          />
        </div>
      </div>
      <div className="dropzone-parent-container">
        <div
          className="dropzone-container "
          {...getFirstRootProps({
            className: `dropzone ${!firstFileStatus ? "disabled" : ""}`,
            style: {
              ...firstStyle,
              pointerEvents: firstFileStatus ? "auto" : "none",
              opacity: firstFileStatus ? 1 : 0.5,
              cursor: firstFileStatus ? "pointer" : "default",
            },
          })}
        >
          <input {...getFirstInputProps()} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isFirstLoading ? (
              <CircularProgress />
            ) : (
              <>
                <p className="description-font" style={{ color: "grey" }}>
                  Subir herramienta feedback
                </p>
                <img
                  className="dropzone-image"
                  style={{ width: "50px", marginLeft: "20px" }}
                  src="/cloud.png"
                  alt="cloud"
                />
              </>
            )}
          </div>
        </div>
        <div
          className="dropzone-container"
          {...getSecondRootProps({
            className: `dropzone ${!secondFileStatus ? "disabled" : ""}`,
            style: {
              ...secondStyle,
              pointerEvents: secondFileStatus ? "auto" : "none",
              opacity: secondFileStatus ? 1 : 0.5,
              cursor: secondFileStatus ? "pointer" : "default",
            },
          })}
        >
          <input {...getSecondInputProps()} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {isSecondLoading ? (
              <CircularProgress />
            ) : (
              <>
                <p className="description-font" style={{ color: "grey" }}>
                  Subir herramienta diligenciada
                </p>
                <img
                  className="dropzone-image"
                  style={{ width: "50px", marginLeft: "20px" }}
                  src="/cloud.png"
                  alt="cloud"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
