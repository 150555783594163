import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({
  open,
  handleClose,
  content,
  btnText,
  btnRef,
  handeClose,
  title,
}) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ color: "red" }}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="alertBtn">
            {btnText}
          </Button>
          <Button
            autoFocus
            variant="alertBtn"
            type="submit"
            onClick={() => btnRef.current.click()}
          >
            Ok
          </Button>

          <Button
            autoFocus
            variant="alertBtn"
            type="submit"
            onClick={() => handeClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
