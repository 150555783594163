import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import { Cancel } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { TextField, Box, Typography } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateAccountDialog({ open, setOpen }) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
      email_address: data.email,
      company: data.company,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/new-user-request/`, body)
      .then((res) => {
        toast.success("Tu solicitud ha sido enviada exitosamente");
        setTimeout(() => {
          handleClose();
        }, 500);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.dismiss();
        if (error.response) {
          const errorData = error?.response?.data?.message;
          if (errorData?.first_name) {
            toast.error(`${errorData?.first_name}`);
          }
          if (errorData?.last_name) {
            toast.error(`${errorData?.last_name}`);
          }
          if (errorData?.phone_number) {
            toast.error(`${errorData?.phone_number}`);
          }
          if (errorData?.email_address) {
            toast.error(`${errorData?.email_address}`);
          }
          if (errorData?.company) {
            toast.error(`${errorData?.company}`);
          }
        } else {
          toast.error("Algo salió mal");
        }
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={{ p: "50px" }}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogActions>
          <Button variant="alertBtn" onClick={handleClose}>
            <Cancel />
          </Button>
        </DialogActions>

        <Box
          sx={{
            p: "0 0 20px 28px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
            }}
            component="h2"
            variant="h5"
          >
            Crear solicitud de cuenta
          </Typography>
        </Box>

        <Box>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <DialogContent
              sx={{
                p: "20px 40px",
              }}
            >
              <TextField
                name="first_name"
                variant="outlined"
                label="Nombre de pila *"
                fullWidth
                {...register("firstName", {
                  // required: 'First name is required',
                  required: "Se requiere el primer nombre",
                  pattern: {
                    value: /^\S.*$/,
                    // message: 'Early spaces are not allowed in first name',
                    message: "No se permiten espacios tempranos en el nombre",
                  },
                })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              &nbsp;
              <TextField
                name="last_name"
                variant="outlined"
                label="Apellido *"
                fullWidth
                {...register("lastName", {
                  // required: 'Last name is required',
                  required: "Se requiere apellido",
                  pattern: {
                    value: /^\S.*$/,
                    // message: 'Early spaces are not allowed in last name',
                    message: "No se permiten espacios tempranos en el apellido",
                  },
                })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
              &nbsp;
              <TextField
                name="phone_number"
                variant="outlined"
                label="Número de teléfono"
                fullWidth
                {...register("phone", {
                  pattern: {
                    value: /^\S.*$/,
                    // message: 'Early spaces are not allowed in phone number',
                    message:
                      "No se permiten espacios tempranos en el número de teléfono.",
                  },
                  minLength: {
                    value: 10,
                    // message: 'Number Length must be greater then 9',
                    message: "La longitud del número debe ser mayor que 9",
                  },
                })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
              &nbsp;
              <TextField
                name="email_address"
                variant="outlined"
                label="Correo electrónico *"
                fullWidth
                {...register("email", {
                  // required: 'Email is required',
                  required: "Correo electronico es requerido",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Formato de correo electrónico incorrecto",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              &nbsp;
              <TextField
                name="company"
                variant="outlined"
                label="Compañía"
                fullWidth
                {...register("company", {
                  pattern: {
                    value: /^\S.*$/,
                    // message: 'Early spaces are not allowed in company',
                    message: "No se permiten espacios tempranos en compañía.",
                  },
                })}
                error={!!errors.company}
                helperText={errors.company?.message}
              />
            </DialogContent>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                type="submit"
                variant="alertBtn"
                size="large"
                loading={isLoading}
              >
                Crear solicitud
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
