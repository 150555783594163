import React from "react";

const Principles = ({ listOfPrinciples }) => {
  return (
    <div className="main-container">
      <div>
        <div>
          {listOfPrinciples?.data?.data.map((principle, index) => (
            <div className="principle-container" key={index}>
              <div
                className="left-content"
                style={{
                  flex: "0 0 30%",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "30px",
                }}
              >
                <div>
                  <img
                    src={principle?.icon}
                    alt="checklist"
                    style={{
                      maxWidth: "23px",
                      minWidth: "23px",
                      //  marginRight: "15px"
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <p style={{ fontSize: "14px" }}>{`Principio ${index + 1}`}</p>
                  <div className="header-font" style={{ fontWeight: "bolder" }}>
                    {principle.title}
                  </div>
                </div>
              </div>
              <div
                className="right-content"
                style={{
                  flex: "0 0 70%",
                  display: "flex",
                }}
              >
                <p style={{ fontSize: "14px", marginRight: "35px" }}>
                  {principle.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/glass.png"
                alt="glass"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>Principio 2</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Autorregulación publicitaria
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Los mensajes publicitarios diseñados por las compañías incluirán,
              de manera voluntaria, criterios adicionales a los exigidos por la
              ley, buscando una difusión responsable, honesta y veraz de los
              mensajes sobre sus productos y/o servicios.
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/leave.png"
                alt="leave"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>Principio 3</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Sostenibilidad y propósito de marca
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Las empresas difundirán mensajes y productos que promuevan la
              construcción de sociedades cada vez más sostenibles, y marcas y
              empresas con propósitos, empatía, responsabilidad y que prioricen
              a las personas para lograr un futuro mejor.
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/organism.png"
                alt="organism"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>Principio 4</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Cadena de producción publicitaria
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Las empresas promoverán en su cadena de producción publicitaria el
              cumplimiento de principios generales de la publicidad y las
              mejores prácticas orientadas a proteger, promover y garantizar la
              efectividad y el libre ejercicio de los derechos de los
              consumidores.
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              // flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/gender.png"
                alt="gender"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "20px",
              }}
            >
              <p style={{ fontSize: "14px" }}>Principio 5</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Adecuada representación de género en la comunicación comercial
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Las empresas promoverán en su cadena de producción publicitaria el
              cumplimiento de principios generales de la publicidad y las
              mejores prácticas orientadas a proteger, promover y garantizar la
              efectividad y el libre ejercicio de los derechos de los
              consumidores.
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/cloud.png"
                alt="cloud"
                style={{ width: "30px", marginRight: "15px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>Principio 6</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Ecosistema digital
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              La empresas estarán comprometidas con una adecuada comunicación
              comercial en el ecosistema digital, fomentarán la transparencia y
              el buen uso de los datos de los consumidores, al tiempo que
              protegen la seguridad y visibilidad digital de su marca.
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/influencer.png"
                alt="influencer"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingLeft: "20px",
              }}
            >
              <p style={{ fontSize: "14px" }}>Principio 7</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Comunicación responsable a través de influenciadores
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Las empresas estarán comprometidas con una comunicación comercial
              responsable a través de influenciadores de manera que los
              consumidores puedan identificar claramente el propósito comercial
              de la comunicación y puedan contar con mensajes veraces que
              reflejan las opiniones y la experiencia
            </p>
          </div>
        </div>
        <div className="principle-container">
          <div
            className="left-content"
            style={{
              flex: "0 0 30%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <div>
              <img
                src="/boys.svg"
                alt="boys"
                style={{ width: "30px", marginRight: "27px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>Principio 8</p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                Protección de niños, niñas, adolecentes
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 70%",
              display: "flex",
            }}
          >
            <p style={{ fontSize: "14px", marginRight: "35px" }}>
              Las empresas difundirán mensajes y productos que promuevan la
              construcción de sociedades cada vez más sostenibles, y marcas y
              empresas con propósitos, empatía, responsabilidad y que prioricen
              a las personas para lograr un futuro mejor.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Principles;
