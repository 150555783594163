import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Button, CircularProgress, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

const SinglePrinciple = ({ item }) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => setAcceptedFiles(acceptedFiles),
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
    },
  });

  const [questionsData, setQuestionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/principal/question/?principal=${item?.id}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("auth_token")}`,
            },
          }
        );
        // toast.success(response?.data?.message);

        setQuestionsData(response?.data);
      } catch (error) {
        // toast.error(error?.response?.data?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (item?.id) {
      fetchData();
    }
  }, [item?.id]);

  const handleImageClick = (question) => {
    setSelectedQuestion(question);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedQuestion(null);
    setInputValue("");
    setAcceptedFiles([]); // Clear accepted files
  };

  const handleSave = () => {
    // Perform save action here
    handleCloseModal();
  };

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const { isFocused, isDragAccept, isDragReject } = useDropzone({});

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    margin: "20px 0px",
    borderWidth: 2,
    borderRadius: "12px",
    borderColor: "grey",
    backgroundColor: "white",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(() => {
    return {
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    };
  }, [isFocused, isDragAccept, isDragReject]);
  const saveData = async () => {
    if (!inputValue) {
      toast.error("por favor agregue comentarios");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();

    // Append the question ID to the FormData
    formData.append("question", selectedQuestion.id);

    // Append the comment to the FormData
    formData.append("comment", inputValue);

    // Append each selected file to the FormData
    acceptedFiles.forEach((file, index) => {
      formData.append(`files_of_comment`, file);
    });

    try {
      // Make a POST request to your API endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/principal/upload/comment/file/`,
        formData,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("auth_token")}`,
          },
        }
      );
      toast.success(response?.data?.message);

      handleCloseModal();
      setIsLoading(false);
    } catch (error) {
      // toast.error(error?.response?.data?.detail);
      toast.error("No tienes permiso para agregar comentarios");
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="main-container">
        <div className="container-header">
          <div
            className="left-content"
            style={{
              flex: "0 0 40%",
              display: "flex",
              alignItems: "center",
              paddingLeft: "60px",
            }}
          >
            <div>
              <img
                src={item?.icon}
                alt="checklist"
                style={{ width: "23px", marginRight: "15px" }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p style={{ fontSize: "14px" }}>
                Principio {item?.sorting_index}
              </p>
              <div className="header-font" style={{ fontWeight: "bolder" }}>
                {item?.title}
              </div>
            </div>
          </div>
          <div
            className="right-content"
            style={{
              flex: "0 0 60%",
              display: "flex",
            }}
          >
            <p
              className="description-font"
              style={{ fontSize: "14px", marginRight: "35px" }}
            >
              {item?.description}
            </p>
          </div>
        </div>

        <div className="comment-section-body">
          <div
            className="comment-header"
            style={{
              display: "flex",
            }}
          >
            <div style={{ flex: "0 0 20%" }}># Pregunta</div>
            <div style={{ flex: "0 0 80%" }}> Comentario</div>
          </div>

          {questionsData &&
            questionsData?.data?.map((question, index) => (
              <div className="comment-body" key={question.id || Math.random()}>
                <div style={{ flex: "0 0 20%", paddingLeft: "70px" }}>
                  {index + 1}
                </div>
                <div style={{ flex: "0 0 70%", paddingLeft: "15px" }}>
                  {question?.question && question.question.length > 80
                    ? question.question.slice(0, 80) + " ... "
                    : question.question}
                </div>

                <div style={{ flex: "0 0 10%" }}>
                  <img
                    className="attachment-img"
                    style={{
                      width: "35px",
                      marginLeft: "40px",
                      cursor: "pointer",
                    }}
                    src="/attachment.png"
                    alt="attach-button"
                    onClick={() => handleImageClick(question)}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "24px",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>
              <b>Pregunta #</b> {selectedQuestion && selectedQuestion.id}
            </p>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </div>
          <hr />
          {selectedQuestion && (
            <div>
              <p>{selectedQuestion.question}</p>
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <label htmlFor="comment">Comentarios</label>
            <textarea
              id="comment"
              name="comment"
              rows="4"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "10px",
                boxSizing: "border-box",
                resize: "none",
              }}
            />
          </div>
          <div>
            <div
              className="dropzone-container"
              {...getRootProps({ className: "dropzone", style })}
            >
              <input {...getInputProps()} />
              <div style={{ display: "flex" }}>
                <p className="description-font" style={{ color: "grey" }}>
                  Subir anexo
                </p>
                <img
                  className="dropzone-image"
                  style={{ width: "40px", height: "30px", marginLeft: "20px" }}
                  src="/attachment.png"
                  alt="attachment"
                />
              </div>
            </div>
            {acceptedFiles?.length > 0 && (
              <aside>
                <h4>Selected file:</h4>
                <ul>{files}</ul>
              </aside>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "20px", height: "20px", marginRight: "5px" }}
              src="/intro.png"
              alt="info"
            />
            <p style={{ color: "grey" }}>Puedes subir más de un archivo</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              className="question-modal-cancel-button"
              onClick={handleCloseModal}
            >
              CANCELAR
            </Button>
            <Button
              className="question-modal-save-button"
              onClick={saveData}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress animation="border" size="sm" />
              ) : (
                "GUARDAR"
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SinglePrinciple;
