import React, { useState, useEffect } from "react";
import SearchBar from "../components/Dashboard/Search/SearchBar";
import SubCategories from "../components/Dashboard/Search/SubCategories";
import Box from "@mui/material/Box";
import "../styles/style.css";

import Stack from "@mui/material/Stack";
import { pdfBottomBtnStyles } from "../jsxStyles";

import { QueryClient } from "react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { downloadPdf } from "../utils";
import toast, { Toaster } from "react-hot-toast";
import { useOutletContext } from "react-router-dom";

import Alert from "../components/Global/Alert";

import Button from "@mui/material/Button";

import { useQueryGetSearchedData } from "../hooks/react-query/useData";

import SearchResults from "../components/Dashboard/Search/SearchResults";
import { useParams, useSearchParams } from "react-router-dom";

import LoadingDots from "../components/Global/LoadingDots";

import PdfDisplayFlow from "../components/Global/PdfDisplayFlow";
import { Grid, useTheme, useMediaQuery, Typography } from "@mui/material";
import CategoryBox from "../components/Dashboard/Search/CategoryBox";
import SubCategoryIcon from "../components/Dashboard/Search/SubCategoryIcon";
import AlertDialog from "../components/Global/Alert";

const SearchPage = () => {
  // Initialize QueryClient for caching
  const queryClient = new QueryClient();

  // State variables
  const [displayPdf, setDisplayPdf] = useState(false);
  const [searchedString, setSearchedString] = useState("");
  const [pdfFileUrl, setPdfFileUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [docId, setDocId] = useState("");
  const [selectedSubCategoryData, setSelectedSubCategoryData] = useState({});
  const { categories } = useOutletContext();
  let { category_name } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  let subCategories = categories?.find(
    (item) => item.id == searchParams.get("id")
  );

  // Extract category properties
  const categoryDescription = subCategories?.description;
  const categoryColor = subCategories?.color_code;
  const categoryId = subCategories?.id;
  subCategories = subCategories?.subcategories;

  // UseQuery hook for fetching search data
  const {
    refetch: getSearchedData,
    isLoading,
    data: searchedData,
  } = useQueryGetSearchedData({
    category: searchParams.get("id"),
    sub_category: Object.keys(selectedSubCategoryData).map(Number),
    question: searchedString,
  });

  // Remove query when category changes
  useEffect(() => {
    queryClient.removeQueries({ queryKey: "getSearchedData" });
  }, [searchParams.get("id")]);

  // Clear selected subcategory when category name changes
  useEffect(() => {
    setSelectedSubCategoryData({});
  }, [category_name]);

  // Function to initiate search
  const searchFromBackend = () => {
    if (Object.keys(selectedSubCategoryData).length === 0) {
      setOpen(true);
      return;
    }
    getSearchedData();
  };

  // Close modal function
  const handleClose = () => {
    setOpen(false);
  };

  // Open alert dialog function
  const openAlertDialog = () => {
    setOpen(true);
  };

  return (
    // Main container
    <Box sx={{ ml: "344px" }}>
      {/* AlertDialog component for alerting users */}
      <AlertDialog
        open={open}
        handleClose={handleClose}
        searchFromBackend={getSearchedData}
        content={`Para lograr resultados más rápidos y precisos, recomendamos seleccionar una categoría y subcategoría. Si no estás seguro, simplemente haz clic en el botón "Continuar buscando". Ten en cuenta que no elegir una <b style="color: black">categoría/subcategoría</b> conducirá a una búsqueda en la cual el tiempo de respuesta será más largo ya que la IA tendrá que buscar en todas las categorías/subcategorías antes de obtener resultados.`} // Your content here
        btnText="Elija subcategoría"
      />
      {/* Toast component for displaying notifications */}
      <Toaster position="top-center" reverseOrder={false} />
      {/* Main content container */}
      <div
        className={displayPdf && pdfFileUrl ? "pdf-view" : "category-container"}
      >
        {displayPdf && pdfFileUrl ? (
          // Display PDF if available
          <PdfDisplayFlow
            downloadPdf={downloadPdf}
            pdfFileUrl={pdfFileUrl}
            pdfBottomBtnStyles={pdfBottomBtnStyles}
            docId={docId}
          />
        ) : (
          // Display search results and subcategories
          <Box
            sx={{
              height: "100%",
              display: "flex",
              pl: { sm: "0", md: "0", lg: "46px" },
              columnGap: "14%",
              width: "30%",
            }}
          >
            {/* Grid container for subcategories */}
            <Grid container spacing={mediumScreen ? 4 : 7} direction={"row"}>
              <Grid item xl={3} lg={4} md={12} sm={12}>
                {/* SubCategories component for displaying subcategories */}
                <SubCategories
                  subCategories={subCategories}
                  categoryName={category_name}
                  setSelectedSubCategoryId={setSelectedSubCategoryData}
                  selectedSubCategoryId={selectedSubCategoryData}
                  openAlertDialog={openAlertDialog}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* Search bar and additional content container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingRight: "30px",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "70%",
              xl: "70%",
            },
            height: "30%",
            margin: displayPdf && pdfFileUrl ? "auto" : "0px",
          }}
        >
          {/* Back button for PDF view */}
          {displayPdf && pdfFileUrl && (
            <Button
              sx={{
                textTransform: "capitalize",
                fontWeight: "normal",
                width: "0%",
                alignItems: "center",
                marginLeft: "24px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                mx: "auto",
              }}
              onClick={() => {
                setPdfFileUrl("");
                setDisplayPdf(false);
              }}
            >
              <ArrowBackIcon sx={{ mr: "5px" }} />
              Back
            </Button>
          )}
          {/* Search bar component */}
          <SearchBar
            setSearchedString={setSearchedString}
            searchFromBackend={searchFromBackend}
            sx={{ width: "100%", height: "100%" }}
          />
          {/* Display search results */}
          {!displayPdf && !pdfFileUrl && (
            <div style={{ padding: "30px 0px" }}>
              {isLoading ? (
                // Display loading dots while fetching data
                <div style={{ zIndex: "100" }}>
                  <LoadingDots loaderText="Buscando Datos..." />
                </div>
              ) : (
                // Display search results if available
                searchedData && (
                  <SearchResults
                    setDisplayPdf={setDisplayPdf}
                    searchedData={searchedData?.data?.data}
                    wholeSearchedData={searchedData}
                    setPdfFileUrl={setPdfFileUrl}
                    categoryName={category_name}
                    selectedSubCategoryData={selectedSubCategoryData}
                    stringifiedData={JSON.stringify(searchedData?.data?.data)}
                    setDocId={setDocId}
                  />
                )
              )}
              <div style={{ padding: "30px 0px" }}>
                {/* CategoryBox component for displaying category information */}
                <CategoryBox
                  categoryName={category_name}
                  categoryDescription={categoryDescription}
                  categoryId={categoryId}
                />
              </div>
              {/* Grid container for displaying subcategory details */}
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: "40px",
                }}
              >
                {subCategories?.map((subCategory, index) => (
                  <Grid item lg={6} mb={2} key={index}>
                    {/* Stack component for organizing subcategory details */}
                    <Stack direction={"column"} spacing={1.5}>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        {/* SubCategoryIcon component for displaying subcategory icon */}
                        <SubCategoryIcon
                          color={subCategory.color_code}
                          variant={"lg"}
                        />
                        <Typography
                          variant="h5"
                          component={"p"}
                          fontWeight={"bold"}
                        >
                          {subCategory?.name}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="body1"
                        component={"p"}
                        sx={{ width: "95%" }}
                      >
                        {subCategory?.description}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
};

export default SearchPage;
