import {
  useMutation,
  useQuery,
  useQueryClient,
  QueryClient,
} from "react-query";
import { queryKeys } from "./queryKeys";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

let queryFnProps = { enabled: false, refetchOnWindowFocus: false, retry: 0 };

export const useQueryGetUserDetails = (payload) => {
  const queryKey = [queryKeys.getUserDetails, payload];
  const queryFn = ({ queryKey }) =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/obtain-api-auth-token`, {
      username: queryKey[1].user.email,
      password: queryKey[1].user.password,
    });

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,

    // staleTime:0,
    onSuccess: (res) => {
      localStorage.setItem("chatPlaceholder", true);
    },
    onError: (error) => {
      //  console.log('err msg',msg.error)
      //  return msg
    },
  });
};
export const useQueryUpdateUserDetails = (
  payload,
  queryClient,
  setIsLoading
) => {
  const navigate = useNavigate();
  const queryKey = [queryKeys.updateUserDetails, payload];

  const queryFn = async ({ queryKey }) => {
    setIsLoading(true);
    let data = {};
    if ("formData" in queryKey[1].user) {
      data = queryKey[1].user.formData;
    } else {
      data = queryKey[1].user;
    }

    axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/profile/`, data, {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((response) => {
        // Handle successful response here
        toast.dismiss();
        toast.success("Perfil actualizado con éxito");
        localStorage.setItem(
          "fullName",
          response.data.data.first_name + " " + response.data.data.last_name
        );

        localStorage.setItem("imageURL", response.data.data.image);

        queryClient.invalidateQueries({ queryKey: [queryKeys.getUserInfo] });

        setIsLoading(false);

        navigate("/dashboard/edit");
      })
      .catch((error) => {
        // console.log("vald", error);
        if (error.response) {
          const errorData = error?.response?.data?.message;
          if (errorData?.first_name) {
            toast.error("Se requiere el primer nombre");
          }
          if (errorData?.last_name) {
            toast.error("Se requiere apellido");
          }
          if (errorData?.phone_number) {
            toast.error("Se requiere número de teléfono");
          }
        } else {
          // console.log("Error: ", error?.message);
        }
        setIsLoading(false);
      });
  };

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,

    // staleTime:0,
    onSuccess: (res) => {},
    onError: (error) => {
      // console.log('error here', error);
    },
  });
};

export const useQueryChangeUserPassword = (payload) => {
  const queryKey = [queryKeys.changeUserPassword, payload];

  const queryFn = ({ queryKey }) => {
    let data = {};
    data = queryKey[1].userChangePassword;

    axios.patch(`${process.env.REACT_APP_SERVER_URL}/change-password/`, data, {
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
      },
    });
  };

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,

    // staleTime:0,
    onSuccess: (res) => {
      toast.dismiss();
      toast.success("Profile Updated");
    },
    onError: (error) => {
      //  console.log('err msg',msg.error)
      //  return msg
    },
  });
};

export const useQueryGetAllCategories = () => {
  const queryKey = [queryKeys.getAllCategories];
  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/categories/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
      },
    });

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};
export const useQueryGetUserInfo = () => {
  const queryKey = [queryKeys.getUserInfo];
  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/profile/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
      },
    });

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};
export const useQueryLogoutUser = () => {
  const queryKey = [queryKeys.logOutUser];
  const queryFn = ({ queryKey }) =>
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/logout/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((response) => {
        // console.log(response);
        location.reload();
      })
      .catch((error) => {
        // console.log(error);
      });

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,

    onError: (error) => {},
  });
};

export const useQueryGetSearchedData = (payload) => {
  const queryKey = [queryKeys.getSearchedData, payload];
  const queryFn = ({ queryKey }) => {
    let payload = {};
    if (queryKey[1]?.sub_category?.length > 0) {
      let filteredIds = queryKey[1]?.sub_category;

      payload = {
        ...queryKey[1],
        category: parseInt(queryKey[1].category),
        sub_category: filteredIds,
      };
    } else if (queryKey[1]?.category) {
      payload = {
        question: queryKey[1].question,
        category: parseInt(queryKey[1].category),
      };
    } else {
      payload = { question: queryKey[1].question };
    }

    return axios.post(
      `${process.env.REACT_APP_SERVER_URL}/chatbot/chat/`,
      payload,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );
  };
  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    staleTime: Infinity,
    onError: (error) => {},
  });
};

export const useQueryVerifyUserToken = () => {
  const queryKey = [queryKeys.verifyUserToken];
  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/verify_token/`, {
      headers: {
        Authorization: `Token ${localStorage.getItem("auth_token")}`,
      },
    });

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};

export const useQueryForgotPassword = (payload) => {
  const queryKey = [queryKeys.forgotPassword, payload];
  const queryFn = ({ queryKey }) => {
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/forgot-password/`, {
      ...queryKey[1].user,
    });
  };
  return useQuery(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onError: (error) => {},
    retry: 0,
  });
};

export const useQuerySendFileOnEmail = (payload) => {
  const queryKey = [queryKeys.sendFileOnEmail, payload];
  const queryFn = ({ queryKey }) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/share-file/`, queryKey[1], {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      })
      .then((res) => {
        toast.success("Email enviado");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,

    onSuccess: (res) => {
      // toast.success("Email send")
    },
    // staleTime:0,
    onError: (error) => {
      //  console.log('err msg',msg.error)
      //  return msg
    },
  });
};
// NEW PROTOTYPE

export const useQueryGetListOfPrinciple = () => {
  const queryKey = [queryKeys.getListOfPrinciples];
  const queryFn = ({ queryKey }) =>
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/?sub_category=1`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};
export const useQueryGetQuestionsOfPrinciples = (payload) => {
  const queryKey = [queryKeys.getQuestionsOfPrinciples, payload?.principle];
  const queryFn = ({ queryKey }) =>
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/question/?principal=${queryKey[1]}`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,
    onError: (error) => {},
  });
};
export const useQueryGetUserDocumentStatus = () => {
  const queryKey = [queryKeys.getUserDocumentStatus];
  const queryFn = ({ queryKey }) =>
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/user/document/status/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};

export const useQueryGetCompanyFileHistory = () => {
  const queryKey = [queryKeys.getCompanyFileHistory];
  const queryFn = ({ queryKey }) =>
    axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/company/file/history/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );

  return useQuery(queryKey, queryFn, {
    ...queryFnProps,
    enabled: true,

    onError: (error) => {},
  });
};

export const useQueryGetFileHistory = (offset, limit) => {
  const queryKey = [queryKeys.getfileHistory, offset, limit];
  const queryFn = ({ queryKey }) => {
    const [_key, offset, limit] = queryKey;
    return axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/company/file/history/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
        params: { offset, limit },
      }
    );
  };

  return useQuery(queryKey, queryFn, {
    enabled: true,
    onError: (error) => {},
  });
};

export const useMutationDeleteHistory = (
  payload,
  handleCloseModal,
  queryClient
) => {
  const queryKey = [queryKeys.deleteHistory, payload];
  const queryFn = () =>
    axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/api/principal/file/by/user/${payload}/`,
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("auth_token")}`,
        },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (success) => {
      handleCloseModal();
      queryClient.invalidateQueries({ queryKey: [queryKeys.getfileHistory] });
    },
    onError: (error) => {},
  });
};
