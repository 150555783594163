import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { boxStyle } from "../../../jsxStyles";

const CategoryBox = ({ categoryName, categoryDescription, categoryId }) => {
  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    const importImage = async () => {
      try {
        const imageModule = await import(
          `../../../assets/${categoryId}-bg.jpg`
        );
        const imageURL = imageModule.default;
        setImageSrc(imageURL);
      } catch (error) {}
    };
    importImage();
  }, [categoryId]);
  return (
    <Box style={boxStyle(imageSrc)}>
      <Typography
        variant="h4"
        component={"p"}
        fontSize={"45px"}
        mb={1}
        fontWeight={"bold"}
      >
        {categoryName}
      </Typography>
      <Typography
        variant="p"
        component={"p"}
        fontSize={"24px"}
        textAlign={"left"}
        lineHeight={1.2}
        display={"inline-block"}
        width={"100%"}
        paddingRight={"29px"}
      >
        {categoryDescription}
      </Typography>
    </Box>
  );
};

export default CategoryBox;
