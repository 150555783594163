import React from "react";
import Header from "../components/Global/Header";
import Footer from "../components/Global/Footer";
import { Box } from "@mui/material";

import { useLocation } from "react-router-dom";

export default function Layout({ children }) {
  const { pathname } = useLocation();

  return (
    <Box sx={{ background: "#F8F8F8", minHeight: "100vh" }}>
      {pathname.includes("dashboard") === false ? (
        <>
          <Header />
          {children}
          <Footer />
        </>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
}
