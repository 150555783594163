import React, { useEffect, useState } from "react";
import Hero from "../components/HomePage/Hero";
import LoginForm from "../components/Global/LoginForm";
import ForgotPasswordForm from "../components/Global/ForgotPasswordForm";
import ReactGA from "react-ga4";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { homeHeroSectionContainerStyles } from "../jsxStyles";
import { useLocation } from "react-router-dom";

const Home = () => {
  let [isCurrentForm, setIsCurrentForm] = useState(false);
  const location = useLocation();

  useEffect(() => {
    ReactGA._gaCommandSendPageview(`${location.pathname + location.search}`, {
      username: localStorage.getItem("username"),
      profilePicture: localStorage.getItem("imageURL"),
    });
  }, [location]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        pt: "27px",
        // mb: "70.72px"
        mb: {
          xl: "80.72px",
          lg: "0",
          md: "0",
          sm: "0",
          xs: "0",
        },
      }}
    >
      <Box sx={{ ...homeHeroSectionContainerStyles }}>
        <Hero />

        {!isCurrentForm ? (
          <LoginForm setIsCurrentForm={setIsCurrentForm} />
        ) : (
          <ForgotPasswordForm setIsCurrentForm={setIsCurrentForm} />
        )}
      </Box>
    </Container>
  );
};

export default Home;
