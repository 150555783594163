import React from "react";

const Glossary = () => {
  return (
    <div className="main-container">
      <div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Brand Safety:
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Se llama seguridad de marca a un conjunto de medidas que tienen como
            objetivo proteger la imagen y reputación de una marca frente a la
            influencia negativa o lesiva de contenido
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Competencia desleal:
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "La competencia desleal es una serie de prácticas económicas
            agresivas usadas para obtener una ventaja sobre los competidores de
            manera deshonesta, algunas prácticas de competencia desleal son:
            <p>
              Dumping de precios: vender a un precio inferior al costo final del
              producto. Engaño: hacer creer a los compradores que el producto
              tiene un precio diferente al real. Denigración: difundir
              información falsa sobre los productos de los competidores, o
              publicar comparativas no relevantes. Confusión: buscar parecerse a
              un competidor para que el consumidor compre sus productos en vez
              de los del competidor. Nos podemos dar cuenta de esto si los
              productos son parecidos y los almacenes están cerca Dependencia
              económica: la exigencia de condiciones al proveedor cuando se le
              compra casi toda su producción. Dado que el proveedor depende de
              estas ventas para la existencia de la empresa, no tendría más
              opción que aceptarlas. Desviación de la clientela y explotación de
              la reputación ajena son otros tipos de actos de competencia
              desleal. Monopolización del mercado cuando existe un escenario de
              libre competencia."
            </p>
          </div>
        </div>
        <div
          clas
          className="glossary-body"
          sName="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Economia circular:
          </div>
          <div
            className="description-font  glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            La economía circular es una estrategia que tiene por objetivo
            reducir tanto la entrada de los materiales vírgenes como la
            producción de desechos, cerrando los «bucles» o flujos económicos y
            ecológicos de los recursos
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Economia linear:
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Funciona bajo un sistema en el que todo lo fabricado tiene un final
            y termina por salir del ciclo productivo. Una economía lineal
            tradicionalmente sigue el plan paso a paso de “tomar-hacer-
            disponer”. Esto significa que las materias primas se recolectan y
            luego se transforman en productos que se utilizan hasta que
            finalmente se desechan como desechos. El valor se crea en este
            sistema económico produciendo y vendiendo tantos productos como sea
            posible. Un modelo económico basado en la secuencia toma (materia
            prima), fabricación (productos), uso (consumo), eliminación (de
            residuos no reciclables), que ha demostrado ser insostenible tanto
            por su consumo de recursos como por su impacto ambiental.
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Empoderamiento de las mujeres y las niñas
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "El empoderamiento de las mujeres y las niñas es tener poder y
            control sobre sus propias vidas. Implica concienciación, desarrollar
            autoestima, ampliar sus opciones, más acceso y control de los
            recursos, y acciones para transformar las estructuras e
            instituciones que refuerzan y perpetúan la discriminación y la
            desigualdad de género. Esto implica que para estar empoderadas no
            solo deben tener las mismas capacidades (tales como educación y
            salud) e igual acceso a los recursos y oportunidades (tales como
            tierra y empleo), sino que también deben poder usar esos derechos,
            capacidades, recursos y oportunidades para optar y tomar decisiones
            estratégicas (como las que brindan las posiciones de liderazgo y
            participación en instituciones políticas).<br></br>
            <br></br>
            Además, UNESCO explica, ""Nadie puede empoderar a otra persona: solo
            cada quien puede empoderarse a sí misma o sí mismo para tomar
            decisiones o expresar su opinión. Sin embargo las instituciones,
            incluidas las agencias de cooperación internacional, pueden apoyar
            procesos que propicien el autoempoderamiento de las personas o
            grupos"".<br></br>
            <br></br>
            Los aportes para promover el empoderamiento de las mujeres deben
            facilitar que las mujeres puedan articular sus necesidades y
            prioridades y que tengan un rol más activo en la promoción de esos
            intereses y necesidades. El empoderamiento de las mujeres no puede
            lograrse en un vacío; los hombres deben unirse al proceso de cambio.
            El empoderamiento no debe verse como un juego de suma cero en el
            cual los triunfos de las mujeres automáticamente implican pérdidas
            para los hombres. El aumento del poder de las mujeres en las
            estrategias de empoderamiento no se refiere a un poder dominante, o
            a formas de poder controlador, sino más bien a formas alternativas
            de poder: poder para, poder con, y poder desde el interior. Se trata
            de utilizar las fortalezas individuales y colectivas para alcanzar
            metas comunes sin coerción ni dominio.<br></br>
            <br></br>
            Fuentes: UNICEF, UNFPA, PNUD, ONU Mujeres. “Gender Equality, UN
            Coherence and you”; Oficina de la Asesora Especial en Cuestiones de
            Género y Adelanto de la Mujer (ahora parte de ONU Mujeres) (2001)
            “Important Concepts Underlying Gender Mainstreaming”; UNESCO GENIA
            Toolkit for Promoting Gender Equality in Education"
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Equidad de género
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "La terminología preferida dentro de las Naciones Unidas es igualdad
            de género, en lugar de equidad de género.La equidad de género
            contiene un elemento de interpretación de la justicia social,
            generalmente basada en la tradición, costumbres, religión o cultura,
            más frecuentemente en detrimento de las mujeres. Se ha determinado
            que ese uso de la equidad con respecto al adelanto de las mujeres es
            inaceptable. Durante la conferencia de Beijing en 1995, se acordó
            que se utilizaría el término igualdad.<br></br>
            <br></br>
            Más tarde el comité de la CEDAW lo confirmó en su Recomendación
            General 28: ""Se exhorta a los Estados Partes a utilizar
            exclusivamente los conceptos de igualdad entre la mujer y el hombre
            o la igualdad entre los géneros y no el concepto de equidad entre
            los géneros al cumplir con sus obligaciones en virtud de la
            Convención. En algunas jurisdicciones este último concepto se
            utiliza para referirse al trato justo de la mujer y el hombre en
            función de sus necesidades respectivas. Esto puede incluir un trato
            igual, o un trato diferente pero considerado equivalente en cuanto a
            los derechos, los beneficios, las obligaciones y las
            oportunidades"".<br></br>
            <br></br>
            Fuentes: ONU Mujeres, OSAGI Gender Mainstreaming - Concepts and
            definitions; Comité para la Eliminación de la Discriminación contra
            la Mujer (2010), Recomendación General Nº 28 relativa a las
            obligaciones básicas de los Estados Partes de conformidad con el
            artículo 2 de la Convención sobre la eliminación de todas las formas
            de discriminación contra la mujer".
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Estereotipos de género
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Los estereotipos de género son generalizaciones simplistas de los
            atributos de género, las diferencias y los roles de las mujeres y
            los hombres. Las características estereotipificadas sobre los
            hombres los describen como ompetitivos, codiciosos, autónomos,
            independientes, beligerantes, interesados en los bienes privados.
            Los estereotipos paralelos sobre las mujeres las representan como
            cooperadoras, acogedoras, atentas, comunicativas, orientadas al
            grupo, interesadas en los bienes públicos. Con frecuencia los
            estereotipos se usan ara justificar la discriminación de género más
            ampliamente y pueden reflejarse y reforzarse con las teorías
            tradicionales y las modernas, las leyes y las prácticas
            institucionales. Los mensajes que refuerzan los estereotipos de
            género y la idea que las mujeres son inferiores vienen en una
            variedad de "envases" —desde canciones y anuncios publicitarios
            hasta proverbios tradicionales.
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Huella de carbono
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Es un [indicador ambiental y de sostenibilidad] que pretende
            reflejar «la totalidad de gases de efecto invernadero emitidos por
            efecto directo o indirecto de un individuo, organización, evento o
            producto
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Gas efecto invernadero
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Un gas de efecto invernadero es un gas atmosférico que absorbe y
            emite radiación dentro del rango infrarrojo. Este proceso es la
            fundamental causa del efecto invernadero mejor concido como el
            calentamiento global.
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Identidad de género
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "La identidad de género se refiere a la experiencia de género
            innata, profundamente interna e individual de una persona, que puede
            o no corresponder con la fisiología de la persona o su sexo al
            nacer. Incluye tanto el sentir personal del cuerpo, que puede
            implicar, si así lo decide, la modificación de la apariencia o
            función física por medios quirúrgicos, médicos u otros, así como
            otras expresiones de género que incluyen la vestimenta, la forma de
            hablar y los gestos.<br></br>
            <br></br> Fuentes: UNFPA y Promundo (2010). Engaging Men and Boys in
            Gender Equality and Health. A Global toolkit for action; ONUSIDA
            (2011). Orientaciones terminológicas de ONUSIDA"
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Igualdad de género (igualdad entre mujeres y hombres)
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "Se refiere a la igualdad de derechos, responsabilidades y
            oportunidades de las mujeres y los hombres y de las niñas y los
            niños. La igualdad no significa que las mujeres y los hombres serán
            iguales, sino que los derechos, responsabilidades y oportunidades de
            las mujeres y los hombres no dependerán de si nacieron con
            determinado sexo. La igualdad de género implica que los intereses,
            necesidades y prioridades de mujeres y hombres se toman en cuenta,
            reconociendo la diversidad de diferentes grupos de mujeres y
            hombres. La igualdad de género no es un asunto de mujeres sino que
            concierne e involucra a los hombres al igual que a las mujeres. La
            igualdad entre mujeres y hombres se considera una cuestión de
            derechos humanos y tanto un requisito como un indicador del
            desarrollo centrado en las personas.<br></br>
            <br></br> Fuente: UN Women, OSAGI Gender Mainstreaming - Concepts
            and definitions"
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Incorporación de una perspectiva de género
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "La incorporación de una perspectiva de género es el enfoque que ha
            elegido el Sistema de las Naciones Unidas y la comunidad
            internacional para avanzar con los derechos de las mujeres y las
            niñas, como subconjunto de derechos humanos a los que se dedican las
            Naciones Unidas. No constituye una meta u objetivo per se. Es una
            estrategia para implementar mayor igualdad para las mujeres y niñas
            en relación con los hombres y niños.<br></br>
            <br></br> La incorporación de una perspectiva de género es el
            proceso de valorar las implicaciones que tiene para los hombres y
            para las mujeres cualquier acción que se planifique, ya se trate de
            legislación, políticas o programas, en todas las áreas y en todos
            los niveles. Es una estrategia para conseguir que las preocupaciones
            y experiencias de las mujeres, al igual que las de los hombres, sean
            parte integrante en la elaboración, puesta en marcha, monitoreo y
            evaluación de las políticas y de los programas en todas las esferas
            políticas, económicas y sociales, de manera que las mujeres y los
            hombres puedan beneficiarse de ellos igualmente y no se perpetúe la
            desigualdad. El objetivo final es conseguir la igualdad de género.
            <br></br>
            <br></br>
            Fuentes: UNICEF, UNFPA, PNUD, ONU Mujeres. “Gender Equality, UN
            Coherence and you”, ECOSOC conclusiones convenidas 1997/2"
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Niños, niñas y adolescentes / Población
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            Para efectos del ejercicio, nos referimos a niños, niñas y
            adolescentes y población infantil a la población menor de 18 años
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Representación de género en la comunicación comercial
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "Representación de género" que es la manera, los roles de hombres y
            mujeres que muestran las narrativas publicitarias y que tienen una
            repercusión en los imaginarios y las acciones de las personas
            respecto del género
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Paridad de género
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            La paridad de género es otro término para la igualdad de
            representación de mujeres y hombres en un ámbito determinado. Por
            ejemplo, paridad de género en el liderazgo institucional o en la
            educación superior. Trabajar para lograr la paridad de género (igual
            representación) es un componente clave para lograr la igualdad de
            género y, junto con la incorporación de una perspectiva de género,
            conforman estrategias gemelas.
          </div>
        </div>
        <div
          className="glossary-body"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              paddingLeft: "30px",
            }}
          >
            Propiedad Intelectual
          </div>
          <div
            className="description-font glossary-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            "La propiedad intelectual se refiere a un bien económico y cultural
            que incluye productos intangibles, al igual que productos físicos,
            reconocido en la mayor parte de legislaciones de los países y sujeto
            a explotación económica por parte de los poseedores legales de dicha
            propiedad. En un sentido estrictamente jurídico la propiedad
            intelectual, es lo protegido por las leyes de propiedad intelectual.
            <br></br>
            <br></br>
            La propiedad intelectual (PI) se relaciona con las creaciones de la
            mente: invenciones, obras literarias y artísticas, así como
            símbolos, nombres e imágenes utilizados en el comercio"
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glossary;
