import Box from "@mui/material/Box";

const SubCategoryIcon = ({ color, variant }) => {
  let iconColor = "red";
  switch (color) {
    case "#e80309":
    case "#e30613":
      iconColor = "red";
      break;
    case "#2D2E83":
      iconColor = "blue";
      break;
    case "#F9B000":
      iconColor = "yellow";
      break;
    case "#94c022":
      iconColor = "green";
      break;
    default:
      iconColor = "red";
  }

  return (
    <Box
      component={"img"}
      src={require(`../../../assets/file-${variant}-${iconColor}.svg`)}
      alt={`file-${variant}-${iconColor}`}
      sx={{
        width: variant === "sm" ? "25px" : "50px",
        height: variant === "sm" ? "25px" : "50px",
      }}
    />
  );
};

export default SubCategoryIcon;
