import React from "react";

const TermsOfUse = () => {
  return (
    <div className="main-container">
      <div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Condiciones de uso:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              El destinatario o usuario autorizado de la presente herramienta de
              "Huella de Publicidad Responsable", en adelante la "Herramienta,
              se compromete y garantiza que hará uso de la misma conforme a los
              fines aquí previstos, así como de acuerdo con los compromisos
              contractuales que pueda contar con Deloitte y las siguientes
              limitaciones:
            </p>{" "}
            <p>
              - Abstenerse de realizar cualquier explotación comercial de la
              Herramienta. <br></br>- Realizar únicamente las copias o
              reproducciones estrictamente necesarias para su uso. <br></br>-
              Abstenerse y garantizar que ni él ni cualquier tercer autorizado
              para su acceso y uso, modificarán en forma alguna la Herramienta.{" "}
              <br></br>- Abstenerse de intentar o realizar cualquier desarrollo
              u obra derivada de la "Herramienta❞ <br></br>- Inhibirse de
              conceder cualquier permiso de uso a cualquier tercero no
              autorizado.<br></br>
              <br></br> La autorización de uso de la Herramienta se limita
              exclusivamente a los fines aquí previstos por lo que se prohíbe
              cualquier uso diferente al autorizado y el ejercicio de cualquier
              derecho patrimonial sobre la misma, especial pero no
              exclusivamente, su reproducción, distribución, transformación u
              adaptación, comunicación pública, puesta a disposición y cualquier
              otra forma de utilización.<br></br>
              <br></br>
              El destinatario o usuario autorizado de la Herramienta se
              abstendrá de realizar cualquier registro a su nombre de la misma
              ante autoridades de derecho de autor o de propiedad intelectual,
              actuar como representante de DELOITTE, ni utilizará la Herramienta
              en beneficio de terceros no autorizados.<br></br>
              <br></br>
              DELOITTE se reserva todo y cualquier derecho que pueda recaer
              sobre la Herramienta y en ningún caso, el uso que aquí se autoriza
              se deberá entender como cesión o transferencia total o parcial de
              derecho alguno sobre la misma. "
            </p>{" "}
          </div>
        </div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Consideraciones importantes:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              1) La información que se suministre por parte del evaluador tendrá
              como referencia el año inmediatamente anterior a la fecha en que
              se realice la evaluación. <br></br>
              <br></br>
              2) Por publicidad debe entenderse cualquier forma de ofrecer los
              bienes y servicios, tales como: avisos en prensa, revistas
              impresas, televisión, radio, vallas, etiquetado, material
              promocional -POP, así como medios digitales. <br></br>
              <br></br>
              3) Publicidad Responsable, será aquella que comunica de manera
              ética, transparente y regulada, siendo consciente de los impactos
              tanto positivos como negativos que genera a su audiencia,
              procurando no solo informar, sino que su objetivo es transmitir el
              mensaje deseado de manera responsable y creando conciencia en
              quien la recibe.<br></br>
              <br></br>
              La publicidad responsable tendrá como criterios orientadores los
              siguientes: <br></br>▪ Cumplir con la ley<br></br> ▪ Veraz y
              transparente (verificable y que no transmita promesas de valor
              objetivas que no correspondan a la realidad del producto).
              <br></br> ▪ Auténtica (respetuosa con los derechos de autor){" "}
              <br></br>▪ Clara (que trasmita información suficiente, pertinente
              y oportuna para el consumidor)<br></br> ▪ Comprometida con la
              sociedad, el medio ambiente y el desarrollo sostenible.<br></br> ▪
              Respetuosa con los principios de lealtad comercial y respetuosa de
              la dignidad humana."<br></br>
              <br></br>
              La{" "}
              <strong>
                Asociación Nacional de Anunciantes de Colombia (ANDA)
              </strong>
              , corporación gremial que representa a las empresas anunciantes en
              Colombia ante los diferentes sectores que intervienen en la
              actividad publicitaria nacional e internacional, inscrita en la
              Cámara de Comercio de Bogotá bajo el número 00002009 del Libro I
              de las entidades sin ánimo de lucro, y NIT No. 860071180-1
              representada por <strong>ELIZABETH MELO ACEVEDO</strong>,
              identificado con Cédula de Ciudadanía No. 32.691.055 de
              Barranquilla, actuando en su calidad de Representante Legal, se
              compromete a:<br></br>
              <br></br>
              1. Salvaguardar oda información relacionada con las empresas
              evaluadas en Huella de Publicidad Responsable, y a asegurar que
              está información será onsiderada y tratada como Información
              Confidencial.<br></br>
              <br></br>
              2. Utilizar la información que recibirá de las empresas,
              únicamente para prestar los servicios relacionados con la
              calificación, evaluación y verificación en materia de Publicidad
              Responsable.
            </p>{" "}
          </div>
        </div>

        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Indemnidad:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              El destinatario o usuario autorizado deberá indemnizar y mantener
              indemne a DELOITTE de cualquier acción, reclamación, demanda,
              requerimiento, procedimiento, bien sea judicial o administrativo,
              civil, comercial, tributario, penal, laboral o de cualquier otra
              índole, que surja por parte de terceras personas, o en relación
              con el uso de la Herramienta y que tengan relación directa con
              actos, omisiones o aseveraciones imputables al destinatario o
              usuario autorizado de la Herramienta. Así mismo, el destinatario o
              usuario autorizado de la Herramienta indemnizará, defenderá y
              mantendrá indemne a DELOITTE por todo perjuicio, costo, pérdidas,
              gastos o pasivos de cualquier naturaleza (incluyendo pero sin
              limitarse a, honorarios legales de abogados y costas judiciales),
              en que incurriere DELOITTE, con ocasión de actos, omisiones o
              aseveraciones imputables al destinatario o usuario autorizado de
              la Herramienta, por los cuales sus agentes, subcontratistas y su
              personal en general sea responsable y que hayan sido cometidos por
              razón o como ocasión del uso de la Herramienta. <br></br>
              <br></br>
              Cualquier daño o perjuicio que se le causa a DELOITTE por razón o
              como ocasión de cualquier incumplimiento de las obligaciones del
              destinatario o usuario autorizado de la Herramienta, ya sea por
              acción o por omisión, deberá ser reparado de manera integral y sin
              limitación alguna por parte destinatario o usuario autorizado de
              la Herramienta, ya sea por responsabilidad contractual o
              extracontractual. Cualquier previsión en contrario se entenderá
              como no escrita."
            </p>{" "}
          </div>
        </div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Confidencialidad:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              La Herramienta así como la información que en se incluye en ésta
              corresponden a información de carácter estrictamente confidencial
              y fue elaborada únicamente con los fines previstos en este
              documento. En consecuencia, la Herramienta sólo podrá ser conocida
              y utilizada por el destinatario de la misma y las personas que
              necesariamente requieran acceder a este conforme a los fines
              mencionados, quienes se someterán a los mismos términos de la
              presente obligación de confidencialidad. En todo caso, el
              destinatario de la Herramienta responderá solidariamente junto con
              las personas que accedan en debida forma a éste, por cualquier
              incumplimiento a esta obligación de confidencialidad y/o por
              cualquier revelación o uso no autorizado de la información.{" "}
              <br></br>
              <br></br>
              El destinatario o usuario autorizado deberá tomar las medidas
              tanto técnicas como jurídicas con el fin de garantizar la
              confidencialidad de la información bajo las condiciones aquí
              previstas frente a las personas que necesariamente deban conocer
              la Herramienta. Independientemente de las acciones a las que haya
              lugar por el incumplimiento de las obligaciones aquí previstas, en
              caso en que el destinatario de la Herramienta o las personas
              autorizadas para acceder a éste extravíen o revelen información
              confidencial, darán aviso inmediato a DELOITTE y deberán toma
              todas las medidas razonables y necesarias para minimizar los
              resultados de pérdida de la información o divulgación de la misma
              sin autorización. <br></br>
              <br></br>
              La obligación de reserva y confidencialidad de la Herramienta se
              mantendrá mientras DELOITTE no la revele o publique de manera
              general y por el tiempo que la normativa de propiedad intelectual
              le otorgue protección. "
            </p>{" "}
          </div>
        </div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Derechos de Autor:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              La Herramienta consiste en una creación original del intelecto por
              lo que corresponde a una obra según lo contemplado en el artículo
              3° de la Decisión 351 de la Comunidad Andina de Naciones. En
              consecuencia, se advierte que todos los derechos patrimoniales de
              autor que recaen sobre la Herramienta son de titularidad exclusiva
              de DELOITTE, por lo que se prohíbe cualquier tipo de explotación o
              uso no autorizado, especial pero no exclusivamente, su
              modificación, adaptación, divulgación, comercialización,
              distribución, transformación y alquiler, entre otras. Todos los
              derechos reservados.<br></br>
              <br></br>
              La presente Herramienta corresponde a la actualización de la
              creada en 2017 por DELOITTE.<br></br>
              <br></br>
              Lo anterior sin perjuicio que la “Herramienta”, procedimientos,
              metodologías e información que en ella se incluye pueda ser
              considerada como un secreto comercial de conformidad con el
              artículo 260 de la Decisión 486 de la Comunidad Andina de
              Naciones."
            </p>{" "}
          </div>
        </div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Aviso de responsabilidad:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              La Herramienta tiene fines únicamente informativos por lo que
              DELOITTE no será responsable por la información y/o las
              obligaciones formales o sustanciales a cargo de los usuarios
              autorizados de la misma, ni por la información o resultados que la
              Herramienta arroje basada en los datos suministrados y
              diligenciados por tales usuarios. DELOITTE únicamente se limita a
              suministrar la Herramienta junto con sus respectivas
              instrucciones. En ningún caso, DELOITTE se hará cargo de la
              revisión, verificación, corrección y/o validación de la
              información que debe suministrar y diligenciar el usuario
              autorizado.<br></br>
              <br></br>
              DELOITTE se exime de cualquier responsabilidad por cualquier
              cambio, actualización o modificación en la normatividad,
              regulaciones y/o principios incluidas en la Herramienta y que se
              efectúen con posterioridad a la elaboración de la Herramienta.
              <br></br>
              <br></br>
              Teniendo en cuenta que los resultados que arroje la Herramienta se
              basa en los datos que el usuario autorizado suministre, DELOITTE
              se exime de tales resultados, así como de las obligaciones, gastos
              y/o cargos que el usuario autorizado deba asumir con el fin de
              tomar las acciones que haya lugar. El usuario autorizado es el
              único responsable de tomar las medidas necesarias de manera
              autónoma e independiente respecto de los resultados que arroje la
              Herramienta."
            </p>{" "}
          </div>
        </div>
        <div
          className="terms-of-use-paragraph"
          style={{
            display: "flex",
            paddingTop: "15px",
            borderBottom: "1px solid lightGreen",
            paddingBottom: "20px",
          }}
        >
          <div
            className="description-font"
            style={{
              flex: "0 0 20%",
              fontWeight: "bolder",

              padding: "0px 30px",
            }}
          >
            Aviso de responsabilidad:
          </div>
          <div
            className="description-font term-of-use-paragraph"
            style={{ flex: "0 0 80%" }}
          >
            <p style={{ paddingBottom: "10px" }}>
              La herramienta, documentación, informes, análisis, etc. que le
              sean suministrados por la ANDA al anunciante relacionados con la
              realización de esta iniciativa se entenderá de exclusivo uso
              interno de la Compañía. Queda prohibido cualquier uso de los
              mismos sin la autorización previa y por escrito de la ANDA.{" "}
              <br></br>
              <br></br>
              La ANDA no es responsable de la calidad y veracidad de la
              información entregada por el Anunciante Participante. <br></br>
              <br></br>
              Asimismo, la ANDA no será responsable ante la Compañía o de
              terceros, de los daños que puedan causarse con motivo de las
              actividades a realizar, debido a la información que se entrega con
              retrasos, o que sea inexacta o alterada, o por omisión.
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
