import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { editProfileFunctionalBtnStyles } from "../jsxStyles";
import Typography from "@mui/material/Typography";
import FileUpload from "../components/Dashboard/EditProfile/FileUpload";
import ProfileInfoForm from "../components/Dashboard/EditProfile/ProfileInfoForm";
import toast, { Toaster } from "react-hot-toast";
import {
  useQueryGetUserDetails,
  useQueryGetUserInfo,
  useQueryUpdateUserDetails,
  useQueryChangeUserPassword,
} from "../hooks/react-query/useData";
import { useQueryClient } from "react-query";

const EditProfile = () => {
  const queryClient = useQueryClient();

  let [user, setUser] = useState({});
  let [isLoading, setIsLoading] = useState(false);

  let [userChangePassword, setUserChangePassword] = useState({});
  const [selectedImage, setSelectedImage] = useState("");

  const { data: allUserInfo, refetch } = useQueryGetUserInfo();

  const { refetch: updateUserDetails } = useQueryUpdateUserDetails(
    {
      user,
    },
    queryClient,
    setIsLoading
  );

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      updateUserDetails();
      // navigate("/dashboard");
    }
  }, [user]);

  const onSubmit = async (data, e) => {
    let phoneValue;

    if (data.test === "" || !data.hasOwnProperty("test")) {
      phoneValue = data.phone_number;
    } else {
      phoneValue = data.test;
    }

    let filterData = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number != "" ? data.phone_number : null,
    };

    // e.preventDefault();
    if (selectedImage) {
      const formData = new FormData();

      for (const key in filterData) {
        if (filterData.hasOwnProperty(key)) {
          const value = filterData[key];
          // Append each key-value pair to the FormData object
          formData.append(key, value);
        }
      }
      formData.append("image", selectedImage);
      setUser({ formData });
      return;
    }

    setUser({ ...filterData });

    // setUserChangePassword({ ...filterChangePassword });
    // changeUserPassword()
  };

  return (
    <Box sx={{ ml: "344px", pl: "124px" }}>
      <Toaster position="top-center" reverseOrder={false} />
      {/* <Typography>
        Por favor diligencie el siguiente formulario para crear un usuario
      </Typography> */}
      <Box sx={{ display: "flex", p: "57px 40px 0 0", columnGap: "51px" }}>
        {/* <FileUpload
          allUserInfo={allUserInfo}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
        /> */}
        <ProfileInfoForm
          setUser={setUser}
          user={user}
          updateUserDetails={updateUserDetails}
          allUserInfo={allUserInfo}
          onSubmit={onSubmit}
          isLoading={isLoading}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
        />
      </Box>
    </Box>
  );
};

export default EditProfile;
