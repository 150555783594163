import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";
import { editProfileFunctionalBtnStyles } from "../../jsxStyles";

import LoadingButton from "@mui/lab/LoadingButton";

import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm } from "react-hook-form";
import {
  emailValidations,
  passwordValidations,
} from "../../reactHookFormValidations";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import CustomTextField from "../Global/CustomTextField";
import Typography from "@mui/material/Typography";
import { useQueryGetUserDetails } from "../../hooks/react-query/useData";
import {
  inputFieldsContainerStyles,
  loginFormContainerStyles,
} from "../../jsxStyles";
import CreateAccountDialog from "./CreateAccountDialog";

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignUp({ setIsCurrentForm }) {
  let navigate = useNavigate();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [user, setUser] = useState({});
  const [open, setOpen] = useState("");

  const { refetch: getUserDetails, isLoading } = useQueryGetUserDetails({
    user,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      (async () => {
        let userData = await getUserDetails();

        if (userData?.data?.data?.token) {
          localStorage.setItem("auth_token", userData.data.data.token);
          localStorage.setItem(
            "username",
            userData.data.data.email.split("@")[0]
          );

          localStorage.setItem(
            "fullName",
            userData.data.data.first_name + " " + userData.data.data.last_name
          );

          localStorage.setItem("imageURL", userData.data.data.image);

          navigate("/dashboard");
          return;
        }
        if (userData?.error.message === "Network Error") {
          toast.error("Red no disponible");
          return;
        }

        toast.error("Credenciales incorrectas !! Inténtalo de nuevo");
      })().catch((err) => {
        console.error(err);
      });
    }
  }, [user]);

  const onSubmit = async () => {
    setUser({ email, password });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <Box
      sx={{
        ...loginFormContainerStyles,
      }}
    >
      <CreateAccountDialog open={open} setOpen={setOpen} />

      <Toaster position="top-center" reverseOrder={false} />

      <Box className="login-form-container">
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar> */}
        <Typography
          variant="formTitle"
          sx={{ display: "block", textAlign: "center", fontSize: "28px" }}
        >
          Iniciar sesión{" "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} sx={{ margintop: "30px" }}>
          <CustomTextField
            helperText="Usuario"
            placeholder="Correo electrónico"
            errors={errors}
            register={register}
            type="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
          <CustomTextField
            helperText="La Contraseña"
            placeholder="******"
            sx={{ mt: "17.38px", mb: "20.69px" }}
            errors={errors}
            register={register}
            name="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="formSecondary"
              sx={{ cursor: "pointer" }}
              onClick={() => setIsCurrentForm(true)}
            >
              Olvidó su contraseña?
            </Typography>
            <FormControlLabel
              sx={{ color: "white" }}
              control={
                <input
                  type="checkbox"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "12.74px",
                  }}
                />
              }
              label="Recordar mi usuario"
            />
          </Box>
          <LoadingButton
            type="submit"
            loading={isLoading}
            fullWidth
            variant="loginBtn"
            sx={{ mt: "28.47px" }}
          >
            INGRESAR AHORA
          </LoadingButton>
          <Typography sx={{ color: "white", mt: "7.89px" }}>
            ¿No tienes cuenta para ingresar a nuestro portal?
          </Typography>
          <Button
            variant="createAccount"
            sx={{ p: "0", mt: "6px", fontSize: "1rem" }}
            onClick={() => setOpen(true)}
          >
            Crear Cuenta
          </Button>
        </form>
      </Box>
    </Box>
  );
}
