import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { footerItems } from "../../mock_data";
import {
  typographyVerticallyCenterStyles,
  footerContainerStyles,
  linkedInFafaIconStyles,
} from "../../jsxStyles";

let columnGap = { columnGap: "49px" };

const Footer = () => {
  return (
    <footer style={{ width: "100vw", bottom: 0 }}>
      <Box
        sx={{
          ...footerContainerStyles,
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            columnGap: { xl: "49px", lg: "49px", md: "37px" },
            flexDirection: {
              xl: "inherit",
              lg: "inherit",
              md: "inherit",
              sm: "column",
              xs: "column",
            },
          }}
        >
          {footerItems.map((item, index) => (
            <Typography
              sx={{ ...typographyVerticallyCenterStyles }}
              key={index}
              onClick={() => {
                window.open(item.link, "_blank");
              }}
            >
              {item.name}
            </Typography>
          ))}
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ ...columnGap }}>
          <Box
            sx={{
              display: "flex",
              columnGap: { xl: "20px", lg: "20px", md: "10px" },
              flexDirection: {
                xl: "inherit",
                lg: "inherit",
                md: "inherit",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <i
              className="fa-brands fa-x-twitter"
              aria-hidden="true"
              style={{
                fontSize: "32px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://twitter.com/andacol", "_blank");
              }}
            />
            <i
              className="fa fa-linkedin"
              aria-hidden="true"
              style={{
                ...linkedInFafaIconStyles,
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/anda-asociaci%C3%B3n-nacional-de-anunciantes-de-colombia",
                  "_blank"
                );
              }}
            ></i>
            <i
              className="fa fa-youtube-play"
              aria-hidden="true"
              style={{
                fontSize: "32px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open("https://www.youtube.com/user/AndaCol", "_blank");
              }}
            />

            <Typography
              sx={{ ...typographyVerticallyCenterStyles }}
              onClick={() => {
                window.open("https://andacol.com/", "_blank");
              }}
            >
              https://andacol.com/
            </Typography>
          </Box>
        </Stack>
      </Box>
    </footer>
  );
};

export default Footer;
