import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import DashboardNav from "../components/Global/DashboardLayout";
import SearchPage from "../pages/Search";
import EditProfilePage from "../pages/EditProfile";
import "../styles/style.css";
import ToolGuide from "../components/ToolGuide";
export default function Router() {
  const isAuthenticated = () => {
    // Check if the auth_token exists in the local storage
    const authToken = localStorage.getItem("auth_token");
    return authToken !== null;
  };

  const PrivateRoute = ({ element, children }) => {
    if (isAuthenticated()) {
      return children ? children : element;
    } else {
      return <Navigate to="/" replace />;
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Home />}
      />
      <Route
        path="/dashboard"
        element={<PrivateRoute element={<DashboardNav />} />}
      >
        <Route
          path=":category_name"
          element={<PrivateRoute element={<SearchPage />} />}
        />
        <Route
          path="edit"
          element={<PrivateRoute element={<EditProfilePage />} />}
        />
        <Route
          path="tool_guide"
          element={<PrivateRoute element={<ToolGuide />} />}
        />
      </Route>
    </Routes>
  );
}
