// globals

// globals

export let typographyVerticallyCenterStyles = {
  display: { xs: "block", xl: "grid" },
  alignItems: "center",
  cursor: "pointer",
};
export let footerContainerStyles = {
  p: {
    xs: "0 20px 0 15px",
    sm: "0 30px 0 30px",
    lg: "0 40px 0 50px",
    xl: "0 76px 0 83px",
  },
  zIndex: "50",
  backgroundColor: "#FFFFFF",
  padding: "10px 40px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

export let pdfBottomBtnStyles = {
  position: "fixed",
  bottom: "56px",
  display: "flex",
  justifyContent: "center",
  right: "0",
  left: "344px",
  columnGap: "15px",
};
// export let subCategoriesAndSearchResultsContainerStyles = {
//   display: "flex",
//   pl: "46px",
//   columnGap: "14%",
//   width: "30%",
// };

export let infoIconBlackStyles = {
  width: "30px",
  height: "30px",
};

export let inputFieldsContainerStyles = {
  width: "462px",
  height: "449px",
  background: "#E8112A",
  p: "10px 28.71px 0 28.71px",
  boxShadow: "40px 40px",
  mb: { xs: "150px", xl: "0" },
};
export let loginFormContainerStyles = {
  display: { xs: "flex", xl: "inherit" },
  justifyContent: { xs: "center", xl: "inherit" },
  pt: "10px",
};

export let editProfileFunctionalBtnStyles = {
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  columnGap: "21px",
  mt: { xs: "25%", lg: "5%" },
  mb: { xs: "15%", sm: "15%", md: "15%" },
};
export let homePageSecondaryTextStyles = {
  maxWidth: "898px",
  textAlign: "center",
  ml: { xs: "auto", xl: "inherit" },
  mr: { xs: "auto", xl: "inherit" },
};
export let homeHeroSectionContainerStyles = {
  display: "flex",
  columnGap: "70px",
  flexDirection: {
    xl: "row",
    lg: "row",
    md: "column",
    sm: "column",
    xs: "column",
  },
};
export let linkedInFafaIconStyles = {
  fontSize: "25px",
  background: "black",
  color: "white",
  lineHeight: "1.2",
  padding: "0 5px 0 5px",
  borderRadius: "5px",
  width: "max-content",
  marginLeft: "auto",
  marginRight: "auto",
  cursor: "pointer",
};

export let filtersTextDashboardStyles = {
  color: "white",
  fontSize: "18px",
  mt: "34px",
  display: "flex",
  columnGap: "13px",
  mb: "20px",
  fontFamily: "Helvetica",
};

export let introTextDashboardStyles = {
  width: "400px",
  maxHeight: "280px",
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "left",
  mt: "18px",
  fontSize: "15px",
  letterSpacing: "0px",
  lineHeight: 1,
};

export let paperSearchBarStyles = {
  p: "0 4px 2px 32px ",
  display: "flex",
  alignItems: "center",
  maxWidth: 901,
  borderRadius: "25px",
  ml: "auto",
  mr: "auto",
};
export let drawerStyles = (drawerWidth) => {
  return {
    display: { xs: "block", md: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
      background: "black",
      border: "1px solid #707070",
    },
    background: "black",
  };
};

export let drawerSmStyles = (drawerWidth) => {
  return {
    display: { xs: "none", md: "block" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: drawerWidth,
      background: "black",
    },
    background: "black",
  };
};
export let avatarContainerStyles = {
  display: "flex",
  columnGap: "8px",
  mt: "25px",
  position: "absolute",
  bottom: "20px",
};
export let searchBarAndPdfBoxContainerStyles = {
  mt: "15px",
  textAlign: "center",
  height: "700px",
  ml: "360px",
};
export let staticChat = {
  ml: "344px",
  width: "100%",
  mt: "44px",
};
export let filterIconStyles = {
  width: "25px",
  height: "25px",
  position: "relative",
  top: "3px",
};

export let boxStyle = (backgroundImageUrl) => {
  return {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "top left",
    minHeight: "294px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    color: "white",
    width: "100%",
    textAlign: "left",
    padding: "67px 0px 26px 79px",
    borderRadius: "10px 10px 0px 0px",
  };
};
