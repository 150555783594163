import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import toast, { Toaster } from "react-hot-toast";

import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm } from "react-hook-form";
import { emailValidations } from "../../reactHookFormValidations";

import Box from "@mui/material/Box";
import CustomTextField from "../Global/CustomTextField";
import Typography from "@mui/material/Typography";
import { useQueryForgotPassword } from "../../hooks/react-query/useData";
import {
  inputFieldsContainerStyles,
  loginFormContainerStyles,
} from "../../jsxStyles";
import { BackHand, KeyboardBackspace, SwipeLeftAlt } from "@mui/icons-material";
import CreateAccountDialog from "./CreateAccountDialog";

// TODO remove, this demo shouldn't need to reset the theme.

export default function SignUp({ setIsCurrentForm }) {
  let [user, setUser] = useState({});
  const [open, setOpen] = useState("");

  const { refetch: resetPassword } = useQueryForgotPassword({
    user,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    if (Object.keys(user).length != 0) {
      (async () => {
        let resetPasswordResponse = await resetPassword();

        if (resetPasswordResponse?.data?.data?.message.includes("User doesn")) {
          toast.error(resetPasswordResponse?.data?.data?.message);
          return;
        }
        toast.success(resetPasswordResponse?.data?.data?.message);

        // if (userData?.data?.data?.token) {
        //   localStorage.setItem("auth_token", userData.data.data.token);
        //   localStorage.setItem(
        //     "username",
        //     userData.data.data.email.split("@")[0]
        //   );

        //   navigate("/dashboard");
        //   return;
        // }
        // if (userData?.error.message === "Network Error") {
        //   toast.error("Network Unreachable");
        //   return;
        // }

        // toast.error("Wrong Credentials!! Please try again");
      })().catch((err) => {
        console.error(err);
      });
    }
  }, [user]);

  const onSubmit = async (data) => {
    setUser({ ...data });

    // useQueryForgotPassword()
  };

  return (
    <Box
      sx={{
        ...loginFormContainerStyles,
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      <CreateAccountDialog open={open} setOpen={setOpen} />

      <Box
       className="login-form-container"
      >
        <Box
          sx={{
            pb: "10px",
          }}
        >
          <Button
            onClick={() => setIsCurrentForm(false)}
            sx={{
              textTransform: "uppercase",
            }}
          >
            <KeyboardBackspace /> &nbsp; Atrás para iniciar sesión
          </Button>
        </Box>

        <Typography
          variant="formTitle"
          sx={{ display: "block", textAlign: "center", mb: "10px" }}
        >
          Cambiar contraseña
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} sx={{ margintop: "30px" }}>
          <CustomTextField
            helperText="Cuenta"
            placeholder="Correo electrónico o usuario"
            errors={errors}
            register={register}
            validations={emailValidations}
            type="email"
            name="email"
          />

          <Button
            type="submit"
            fullWidth
            variant="loginBtn"
            sx={{ mt: "28.47px" }}
          >
            ENVIAR
          </Button>
          <Typography sx={{ color: "white", mt: "33.18px" }}>
            ¿No tienes cuenta para ingresar a nuestro portal?
          </Typography>
          <Button
            variant="createAccount"
            sx={{
              p: "0",
              mt: "6px",
            }}
            onClick={() => setOpen(true)}
          >
            Crear cuenta
          </Button>
        </form>
      </Box>
    </Box>
  );
}
