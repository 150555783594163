import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DialogContent from "@mui/material/DialogContent";

import Slide from "@mui/material/Slide";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import { LearnMoreContent } from "../../mock_data";

import medalLogo from "../../assets/learnMore.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LearnMoreDialog({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent style={{ padding: "20px 24px 0 24px" }}>
          <Paper elevation={0} sx={{ padding: "2rem 2rem 0 2 rem" }}>
            <Stack direction="row" justifyContent={"flex-end"} height={"70px"}>
              <DialogActions style={{ height: "90px" }}>
                <IconButton
                  aria-label="cancel-button"
                  style={{ color: "#DFDFDF", width: "0%" }}
                  size="large"
                  onClick={handleClose}
                >
                  <CancelOutlinedIcon
                    style={{ width: "2.5rem", height: "2.5rem" }}
                  />
                </IconButton>
              </DialogActions>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column">
                <Box
                  component="img"
                  src={medalLogo}
                  alt="medal-Logo"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Stack>
              <Stack direction="column" width="100%">
                <Typography
                  variant="p"
                  fontSize={"37px"}
                  fontFamily={"Helvetica"}
                  component={"p"}
                  lineHeight={0.95}
                >
                  Portal de ANDA
                </Typography>
                <Typography
                  variant="p"
                  fontSize={"35px"}
                  fontFamily={"Helvetica"}
                  fontWeight={"600"}
                  component={"p"}
                >
                  Resumen informativo
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={"column"}
              mt={2}
              justifyContent={"center"}
              alignItems={"center"}
              paddingLeft={"2rem"}
            >
              <Typography fontSize={"18px"} fontFamily={"Helvetica"}>
                {LearnMoreContent.content}
              </Typography>
            </Stack>
          </Paper>
        </DialogContent>
        <Divider />
        <DialogContent style={{ padding: "2rem 2rem 1rem 2rem" }}>
          <Grid container spacing={2}>
            <Grid item lg={11} md={10} sm={12} xs={12}></Grid>

            <Grid item lg={1} md={2} sm={12} xs={12}>
              <button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "9px 35px",
                  borderRadius: "25px",
                  fontSize: "12px",
                  fontFamily: "Helvetica",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                SALIR
              </button>
            </Grid>
          </Grid>
        </DialogContent>
        <Box
          display={"block"}
          height={"17px"}
          sx={{ backgroundColor: "#E30613", borderRadius: "12px" }}
        />
      </Dialog>
    </>
  );
}
