import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
const PdfPreview = ({ pdfSource }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <div className="pdf-preview">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
      >
        <Viewer
        plugins={[
          defaultLayoutPluginInstance,
      ]}
          fileUrl={pdfSource}
          defaultToolbar={[]}
          disableTextSelection={true}
        />
      </Worker>
    </div>
  );
};

export default PdfPreview;
