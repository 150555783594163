import React, { useState, useRef, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTheme, useMediaQuery } from "@mui/material";

// informationIcon.png/

const ImageUploader = ({ allUserInfo, setSelectedImage, selectedImage }) => {
  const [previewImage, setPreviewImage] = useState("");
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setPreviewImage(
      allUserInfo?.data?.data?.image
        ? allUserInfo?.data?.data?.image
        : "/loginUser.png"
    );
  }, [allUserInfo]);

  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];

    if (imageFile instanceof File) {
      setSelectedImage(imageFile);
      setPreviewImage(URL.createObjectURL(imageFile));
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    // Profile photo uploader
    <Box className="image-uploader-container">
      <div className="image-uploader">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        <img src={previewImage} alt="Preview" className="preview-image" />

        <button className="upload-button" onClick={handleButtonClick}>
          <img
            src="/transparentCamera-removebg-preview.png"
            style={{ objectFit: "cover", width: "40px", height: "40px" }}
          />
        </button>
      </div>
      <Stack
        direction={"row"}
        spacing={0.25}
        justifyContent={"center"}
        marginLeft={"10px"}
        alignItems={"center"}
        marginBottom={"10px"}
      >
        <Box
          component="img"
          src="/informationIcon.png"
          sx={{
            width: "21px",
            height: "21px",
            position: "relative",
            top: "1%",
            objectFit: "cover",
          }}
        />
        <Typography sx={{ color: "#858585", fontSize: "18px", height: "25px" }}>
          Usar foto no mayor a 500*500
        </Typography>
      </Stack>
    </Box>
  );
};

export default ImageUploader;
