export let emailValidations = {
  required: "Correo electronico es requerido",
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    // message: "Invalid email address",
    message: "Dirección de correo electrónico no válida",
  },
};
export let passwordValidations = {
  required: "Se requiere contraseña",
};
