import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

let arrow = ">";

const Header = () => {
  return (
    <Box
      sx={{
        display: "flex",
        p: {
          xs: "10px 20px 0 25px",
          sm: "10px 40px 0 49px",
          lg: "10px 63px 0 98px",
        },
        justifyContent: "space-between",
      }}
    >
      <Box
        component="img"
        src={require("../../assets/logo@2x.png")}
        // sx={{ width: "11%", cursor: "pointer" }}
        className="responsive-image"
      />
      <Button
        href="https://api.andaafiliados.com/admin/"
        target="_blank"
        variant="header"
        sx={{ mt: "auto", mb: "auto" }}
      >
        Soy administrador {arrow}
      </Button>
    </Box>
  );
};

export default Header;
