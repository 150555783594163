export let queryKeys = {
  getUserDetails: "getUserDetails",
  updateUserDetails: "updateUserDetails",
  getAllCategories: "getAllCategories",
  getUserInfo: "getUserInfo",
  logOutUser: "logOutUser",
  getSearchedData: "getSearchedData",
  forgotPassword: "forgotPassword",
  verifyUserToken: "verifyUserToken",
  sendFileOnEmail: "sendFileOnEmail",
  changeUserPassword: "changeUserPassword",
  //New Prototype
  getListOfPrinciples: "getListOfPrinciples",
  getQuestionsOfPrinciples: "getQuestionsOfPrinciples",
  uploadUserDocument: "uploadUserDocument",
  uploadAnswerOfQuestion: "uploadAnswerOfQuestion",
  getUserDocumentStatus: "getUserDocumentStatus",
  getCompanyFileHistory: "getCompanyFileHistory",
  getfileHistory: "getfileHistory",
  deleteHistory:"deleteHistory"

};
