import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { Cancel } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog({
  open,
  sendFileOnEmail,
  setUserEmail,
  setOpen,
  userEmail,
}) {
  const [emailValidation, setEmailValidation] = useState("");

  const handleSendEmail = () => {
    // Check if the userEmail is empty
    if (!userEmail) {
      setEmailValidation("Correo electronico es requerido");
      // You can also show an error message to the user here if needed
      return;
    }
    // Define a regex pattern for a valid email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Check if the userEmail matches the email regex pattern
    if (!emailRegex.test(userEmail)) {
      setEmailValidation("Formato de correo inválido");
      // You can also show an error message to the user here if needed
      return;
    }
    // If both checks pass, you can proceed to send the email
    // console.log('User Email here', userEmail);
    sendFileOnEmail();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Dialog
        open={open}
        TransitionComponent={Transition}
        sx={{ p: "50px" }}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <form>
          <DialogActions>
            <Button variant="alertBtn" onClick={handleClose}>
              <Cancel />
            </Button>
          </DialogActions>

          <DialogContent sx={{ p: "20px 50px", width: "500px" }}>
            <TextField
              placeholder={"Correo electrónico del usuario"}
              variant="outlined"
              onChange={(e) => setUserEmail(e.target.value)}
              type="email"
              fullWidth
            />
            <Typography sx={{ p: "0", color: "red", fontSize: "small" }}>
              {emailValidation}
            </Typography>
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button variant="alertBtn" onClick={handleSendEmail}>
              Enviar correo electrónico
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
