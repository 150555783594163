import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import SubCategoryIcon from "./SubCategoryIcon";
import { useLocation, useNavigate } from "react-router";
import FileSaver from "file-saver";

const SubCategories = ({
  subCategories,
  categoryName,
  setSelectedSubCategoryId,
  selectedSubCategoryId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const yourArray = [
    { id: 1, icon: "/business.png", name: "Información de la empresa" },
    {
      id: 2,
      icon: "/Book.svg",
      name: "Guía de herramienta huella de comunicación",
    },
    {
      id: 3,
      icon: "/download-alt.png",
      name: "Descarga de herramienta huella de comunicación",
    },
    { id: 4, icon: "/history_data.png", name: "Histórico de data" },
  ];

  const [singleSelectedCategory, setSingleSelectedCategory] = useState(null);
  const onSingleSubCategoryClick = (item) => {
    if (item?.name === "Descarga de herramienta huella de comunicación") {
      window.open(subCategories[0]?.tool_guide_file, "_blank");
    } else {
      navigate("/dashboard/tool_guide", { state: { selectedOption: item } });
    }
  };
  const desiredId = 16;

  const foundSubCategory = subCategories?.find(
    (category) => category?.id === desiredId
  );

  return (
    <Box>
      <Typography sx={{ fontSize: "24px" }}>
        <b>Filtro:</b>{" "}
        {categoryName.length > 90 ? categoryName.slice(0, 90) : categoryName}
      </Typography>
      <List>
        {subCategories?.length &&
          subCategories?.map((subCategoryInfo, index) => {
            // Check if the current subCategoryInfo is the foundSubCategory
            const isFoundSubCategory =
              foundSubCategory && foundSubCategory.id === subCategoryInfo.id;

            return (
              <>
                <Typography
                  sx={{
                    display: "flex",
                    columnGap: "7px",
                    maxWidth: "300px",
                    mb: "14px",
                    cursor: "pointer",
                    color: selectedSubCategoryId[subCategoryInfo?.id]
                      ? subCategoryInfo?.color_code
                      : "black",
                    fontWeight: selectedSubCategoryId[subCategoryInfo?.id]
                      ? "bold"
                      : "normal",
                  }}
                  key={subCategoryInfo?.id}
                >
                  <SubCategoryIcon
                    variant={"sm"}
                    color={subCategoryInfo?.color_code}
                  />

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      onClick={() => {
                        setSelectedSubCategoryId((prevState) => {
                          const updatedState = { ...prevState };
                          if (updatedState[subCategoryInfo?.id]) {
                            delete updatedState[subCategoryInfo?.id];
                          } else {
                            updatedState[subCategoryInfo?.id] = subCategoryInfo;
                          }

                          const selectedSubcategoryIds =
                            Object.keys(updatedState);

                          return updatedState;
                        });
                      }}
                    >
                      {" "}
                      {subCategoryInfo?.name}
                    </div>{" "}
                    <div style={{ color: "black" }}>
                      {isFoundSubCategory && (
                        <img
                          src="/arrow-down.png"
                          alt="Icon"
                          style={{
                            marginRight: "5px",
                            width: "15px",
                          }}
                          onClick={() => {
                            setSingleSelectedCategory(!singleSelectedCategory);
                          }}
                        />
                      )}{" "}
                      {singleSelectedCategory && isFoundSubCategory && (
                        <Typography>
                          <div>
                            <ul>
                              <li>
                                <div
                                  onClick={() =>
                                    onSingleSubCategoryClick(yourArray[0])
                                  }
                                  style={{ display: "flex" }}
                                >
                                  <span>
                                    {" "}
                                    <img
                                      src={yourArray[0].icon}
                                      alt="Icon"
                                      style={{ width: "22px" }}
                                    />
                                  </span>
                                  <span style={{ marginLeft: "8px" }}>
                                    {yourArray[0].name}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    onSingleSubCategoryClick(yourArray[1])
                                  }
                                  style={{ display: "flex" }}
                                >
                                  <span>
                                    {" "}
                                    <img
                                      src={yourArray[1].icon}
                                      alt="Icon"
                                      style={{
                                        width: "23px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </span>
                                  <span style={{ marginLeft: "8px" }}>
                                    {yourArray[1].name}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    onSingleSubCategoryClick(yourArray[2])
                                  }
                                  style={{ display: "flex" }}
                                >
                                  <span>
                                    {" "}
                                    <img
                                      src={yourArray[2].icon}
                                      alt="Icon"
                                      style={{
                                        width: "25px",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </span>
                                  <span style={{ marginLeft: "8px" }}>
                                    {yourArray[2].name}
                                  </span>
                                </div>
                              </li>
                              <li>
                                <div
                                  onClick={() =>
                                    onSingleSubCategoryClick(yourArray[3])
                                  }
                                  style={{ display: "flex" }}
                                >
                                  <span>
                                    {" "}
                                    <img
                                      src={yourArray[3].icon}
                                      alt="Icon"
                                      style={{ width: "22px" }}
                                    />
                                  </span>
                                  <span style={{ marginLeft: "8px" }}>
                                    {yourArray[3].name}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </Typography>
                      )}
                    </div>
                  </div>
                </Typography>
              </>
            );
          })}
      </List>
    </Box>
  );
};

export default SubCategories;
