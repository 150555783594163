import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { paperSearchBarStyles } from "../../../jsxStyles";

export default function CustomizedInputBase({
  setSearchedString,
  searchFromBackend,
  sx,
}) {
  return (
    <Paper
      // component="form"
      sx={{
        ...paperSearchBarStyles,
        ...sx,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Escríbele tu caso a nuestra IA..."
        inputProps={{ "aria-label": "Escríbele tu caso a nuestra IA..." }}
        onChange={(e) => {
          setSearchedString(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            searchFromBackend();
          }
        }}
      />
      <IconButton
        type="button"
        sx={{ p: "10px", background: "none !important" }}
        aria-label="search"
        onClick={() => {
          searchFromBackend();
        }}
      >
        <Box
          className="pdf-btn-img-container "
          component="img"
          src="/searchIcon.png"
          sx={{ height: "20px" }}
        />
      </IconButton>
    </Paper>
  );
}
