import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../../styles/style.css";

const SearchResults = ({
  setDisplayPdf,
  searchedData,
  setPdfFileUrl,
  categoryName,
  selectedSubCategoryData,
  stringifiedData,
  setDocId,
}) => {
  const [expanded, setExpanded] = useState("");
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: "100%" }}>
 {categoryName && (
    <Typography variant="searchResultsSecondary">
      {categoryName}/
      {Array.isArray(selectedSubCategoryData) &&
        selectedSubCategoryData?.map((subCatData, subCatIndex) =>
          subCatData ? (
            <React.Fragment key={subCatIndex}>{subCatData?.name}/</React.Fragment>
          ) : null
        )}
    </Typography>
  )}
  <Box sx={{ mt: "18px" }}>
    {stringifiedData.includes("category_name") === false && searchedData.length ? (
      searchedData &&
      searchedData?.map((data, index) => (
        <Accordion
          key={index}
          onClick={() => {
            setPdfFileUrl(data.source);
            setDisplayPdf(true);
            setDocId(data?.doc_id);
          }}
          expanded={expanded === `panel${index}`}
          onChange={handleAccordionChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ transform: "rotate(270deg)" }} />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
          >
            <Typography sx={{ display: "block", mr: "30px" }}>
              {data.question}
              <br />
              <span style={{ marginTop: "10px", display: "block", color: "#6F6F6F" }}>
                {expanded === `panel${index}` ? data?.answer : data?.answer.substring(0, 100)}
              </span>
            </Typography>
          </AccordionSummary>
        </Accordion>
      ))
    ) : searchedData.length ? (
      searchedData?.map((data, index) =>
        data.sub_categories?.map((subCatData, subCatIndex) => (
          <Accordion key={subCatIndex} expanded={expanded === `panel${index}${subCatIndex}`} onChange={handleAccordionChange(`panel${index}${subCatIndex}`)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ transform: "rotate(270deg)" }} fontSize="large" />}
              aria-controls={`panel${index}${subCatIndex}bh-content`}
              id={`panel${index}${subCatIndex}bh-header`}
            >
              <Typography
                sx={{ display: "block", mr: "30px", textAlign: "left", width: "100%" }}
                onClick={() => {
                  setDocId(subCatData?.doc_id);
                  setPdfFileUrl(subCatData.source);
                  setDisplayPdf(true);
                }}
              >
                {subCatData?.question}
                <br />
                <span style={{ marginTop: "10px", display: "flex", color: "#6F6F6F" }}>
                  {expanded === `panel${index}${subCatIndex}` ? subCatData?.answer : subCatData?.answer.substring(0, 100)}
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ textAlign: "left" }}>
                <b> Category Name:</b> {data?.category_name}
                <br />
                <b>Subcategory Name:</b> {subCatData?.name} <br />
                <b>Source: </b>
                <a className="search-url" href={subCatData?.source} target="_blank">
                  {subCatData?.source.length > 26 ? `${subCatData?.source.slice(0, 26)}...` : subCatData?.source}
                </a>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      )
    ) : !searchedData.length && <p>Datos no encontrados</p>}
  </Box>
</Box>

  );
};

export default SearchResults;
