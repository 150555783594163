import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import userAnda from "../../assets/user-anda.png";
import afiliado from "../../assets/afiliado.png";
import { useNavigate } from "react-router";

const StaticChat = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const navigate = useNavigate();

  const handleIntoBtn = () => {
    localStorage.setItem("chatPlaceholder", false);
    navigate("/dashboard");
  };

  return (
    <>
      <Box sx={{ paddingTop: "10px" }}>
        {/* chatbot text end */}
        <Grid container spacing={1} sx={{ paddingTop: "39px" }}>
          <Grid item xs={12} md={1}>
            <Box
              height={isSmallScreen ? "50%" : "80%"}
              width={"80%"}
              minWidth={"50px"}
            >
              <img
                src={userAnda}
                alt="user-anda"
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px 30px 10px",
                width: "94%",
                height: "84%",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "10px",
                    height: "10px",
                    background: "#1BEB29 0% 0% no-repeat padding-box",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#8D8D8D",
                    fontFamily: "Poppins",
                    letterSpacing: 0,
                    paddingLeft: "10px",
                  }}
                >
                  ANDA-GPT
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  padding: "0 0 6px 19px",
                  fontFamily: "Poppins",
                  width: "100%",
                  letterSpacing: 0,
                }}
              >
                ¡Saludos! Quería hablarte sobre nuestra nueva herramienta
                tecnológica. ¿Te gustaría descubrir cómo puede facilitar tu
                búsqueda en la plataforma y mantenerte al día con los documentos
                y presentaciones relevantes?
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* user text end */}
        <Grid container spacing={2} sx={{ paddingTop: "39px" }}>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#E8112A",
                color: "#FFFFFF",
                width: "78%",
                borderRadius: "10px",
                height: "90%",
              }}
            >
              <Box
                sx={{
                  paddingTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  height: "18px",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "10px",
                    height: "10px",
                    background: "#1BEB29 0% 0% no-repeat padding-box",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    color: "#FFFFFF",
                    padding: "6px 15px 1px 10px",
                  }}
                >
                  Afiliado
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  padding: "6px 20px 20px 30px",
                  letterSpacing: 0,
                  width: "100%",
                }}
              >
                Hola, suena interesante. Me encantaría saber más sobre cómo esta
                nueva plataforma puede ayudarme en mi día a día y cómo puedo
                acceder a los recursos necesarios.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              height={isSmallScreen ? "50%" : "80%"}
              width={"80%"}
              minWidth={"50px"}
            >
              {!!localStorage.getItem("imageURL") ? (
                <img
                  src={localStorage.getItem("imageURL")}
                  alt="afiliado"
                  className="user-profile-static-chat"
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={afiliado}
                  alt="afiliado"
                  style={{
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* chatbot text end */}
        <Grid container spacing={1} sx={{ paddingTop: "39px" }}>
          <Grid item xs={12} md={1}>
            <Box
              height={isSmallScreen ? "50%" : "80%"}
              width={"80%"}
              minWidth={"50px"}
            >
              <img
                src={userAnda}
                alt="user-anda"
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px 30px 10px",
                width: "94%",
                height: "84%",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "10px",
                    height: "10px",
                    background: "#1BEB29 0% 0% no-repeat padding-box",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#8D8D8D",
                    fontFamily: "Poppins",
                    letterSpacing: 0,
                    paddingLeft: "10px",
                  }}
                >
                  ANDA-GPT
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  padding: "0 0 6px 19px",
                  fontFamily: "Poppins",
                  width: "100%",
                  letterSpacing: 0,
                }}
              >
                ¡Excelente! Nuestra plataforma tecnológica te brinda acceso
                rápido y sencillo a todos los documentos y presentaciones
                importantes de ANDA. Puedes encontrarlos organizados por
                categorías, subcategorías y temas relevantes, lo que te
                permitirá ubicarlos fácilmente.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* user text end */}
        <Grid container spacing={2} sx={{ paddingTop: "39px" }}>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "#E8112A",
                color: "#FFFFFF",
                width: "55%",
                height: "90%",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "10px",
                    height: "10px",
                    background: "#1BEB29 0% 0% no-repeat padding-box",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    color: "#FFFFFF",
                    padding: "6px 15px 0px 10px",
                  }}
                >
                  Afiliado
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  padding: "0px 20px 20px 30px",
                  letterSpacing: 0,
                  width: "100%",
                }}
              >
                Eso suena muy útil. ¿Cómo puedo acceder a la plataforma?
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={1}>
            <Box
              height={isSmallScreen ? "50%" : "80%"}
              width={"80%"}
              minWidth={"50px"}
            >
              {!!localStorage.getItem("imageURL") ? (
                <img
                  src={localStorage.getItem("imageURL")}
                  alt="afiliado"
                  className="user-profile-static-chat"
                  style={{
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={afiliado}
                  alt="afiliado"
                  style={{
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>

        {/* chatbot text end */}
        <Grid container spacing={1} sx={{ paddingTop: "39px" }}>
          <Grid item xs={12} md={1}>
            <Box
              height={isSmallScreen ? "50%" : "80%"}
              width={"80%"}
              minWidth={"50px"}
            >
              <img
                src={userAnda}
                alt="user-anda"
                style={{ objectFit: "cover" }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Paper
              elevation={0}
              sx={{
                padding: "10px 20px 30px 10px",
                width: "94%",
                height: "84%",
                borderRadius: "10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    borderRadius: "50px",
                    width: "10px",
                    height: "10px",
                    background: "#1BEB29 0% 0% no-repeat padding-box",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#8D8D8D",
                    fontFamily: "Poppins",
                    letterSpacing: 0,
                    paddingLeft: "10px",
                  }}
                >
                  ANDA-GPT
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  padding: "0 0 6px 19px",
                  fontFamily: "Poppins",
                  width: "100%",
                  letterSpacing: 0,
                }}
              >
                Es muy sencillo. Solo necesitas visitar nuestro sitio web de
                ANDA e iniciar sesión con tus credenciales de afiliado. Una vez
                dentro, encontrarás un menú principal con todas las secciones
                disponibles.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "30px",
          paddingRight: "120px",
        }}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={handleIntoBtn}
          sx={{
            textTransform: "uppercase",
            borderRadius: "30px",
            background: "#000",
            color: "#fff",
            "&:hover": {
              background: "#000",
              color: "#fff",
            },
          }}
        >
          OMITIR INTRODUCCIÓN
        </Button>
      </Box>
    </>
  );
};

export default StaticChat;
